.container {
  position: relative;
  margin-bottom: 8px;

  .inputContainer {
    display: flex;
    align-items: center;
    position: relative;

    .input {
      width: 100%;
      padding: 12px;
      margin-bottom: 4px;
      border-radius: var(--radius-medium);
      font-size: var(--fontsize-14);
      color: var(--grey800);
      border: 1px solid var(--grey300);
      outline: none;
      text-transform: uppercase;
    }

    .input:focus {
      background-color: var(--white);
      border-radius: var(--radius-medium);
      outline: none;
      border-color: var(--brand);
    }

    .input::placeholder {
      text-transform: initial;
    }

    .icons {
      display: flex;
      align-items: center;
      position: absolute;
      right: 12px;
      top: 12px;
      margin: auto;

      .closeIcon {
        transform: scale(0.65);
        cursor: pointer;
        margin-right: 4px;
      }

      .dropdownIcon {
        cursor: pointer;
      }
    }
  }

  .popup {
    padding: 12px 24px;
    width: fit-content;
    min-width: 175px;
    height: fit-content;
    border: 1px solid var(--grey300);
    border-radius: 8px;
    position: absolute;
    right: 0;
    margin: auto;
    margin-top: 8px;
    z-index: 2000;
    background: var(--white);
    display: flex;
    flex-flow: column;
    align-items: center;

    .timePicker {
      margin-bottom: 8px;

      label {
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 16px;
        color: var(--grey500);
      }
    }

    .saveButton {
      margin-top: 4px;
      width: 100%;
    }
  }
}
