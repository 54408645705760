.container {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;

  padding: 16px 24px;
  border-radius: 8px;
  box-shadow: 0 2px 16px rgb(14 15 57 / 5%), 0 2px 8px rgb(14 15 57 / 5%);
  margin-bottom: 12px;
  width: 100%;
  background-color: #FCEBB8;
  border: 1px solid #FFEFCD;
  color: #866220;
  font-family: "Inter", sans-serif;

  h1 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  ul {
    list-style: inside;
    margin-bottom: 8px;
    color: #866220;

    li {
      line-height: 24px;
    }
  }

  p {

  }
}

.alertIcon {
  @media (max-width:768px) {
    display: none;
  }
}