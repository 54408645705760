@import "src/styles/v2/variables";

.content {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;

  /* Grays/Grey 300 */

  border: 1px solid #D1D5DB;
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.detailsRow {
  /* Auto layout */
  flex: 0 0 100%; 
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 20px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  
  @include mobile-breakpoint {
    padding-right: 45px;
    flex-wrap: wrap;
  }
}

.separator {
  height: 0px;
  /* Grays/Grey 300 */

  border: 1px solid #D1D5DB;

  flex: none;
  align-self: stretch;
  flex-grow: 0;
}

.item {
  width: calc(50% - 8px);
  display: flex;
  justify-content: flex-start;
  
  .oneLineTitle {
    color: var(--grey700);
    font-family: 'Inter';

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .oneLineDescription {
    color: var(--grey500);
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }

  h3 {
    color: var(--grey700);
    font-family: 'Inter';

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    width: 42rem;

    @include mobile-breakpoint {
      width: 15rem;
    }
    /* identical to box height */

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .itemListDescriptionContainer {
    color: var(--grey500);
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    display: flex;
    align-items: center;

    .iconBullet {
      margin-top: 4px;
    }

    @include mobile-breakpoint {
      margin-top:0.5rem;
    }
  }
  
  .itemDescriptionContainer {
    color: var(--grey500);
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
  }

  .icon {
    margin-right: 8px;
  }
}
