@import "src/styles/v2/variables";

.productOptionsContainer {
  background: #1F1F1F;
  box-shadow: 0px 2px 16px rgba(14, 15, 57, 0.05), 0px 2px 8px rgba(14, 15, 57, 0.05);
  border-radius: 8px;
  padding: 40px 40px 32px;

  @include mobile-breakpoint {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    // align-content: center;
    justify-content: space-between;
    align-items: center;

    padding: 32px 0;
    width: 100%;

  }
  
  .container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
  
    /* Scrollable */
      box-sizing: border-box;
      max-height: 360px;
      overflow-y: scroll;
      overflow-x: hidden;

      padding-right: 15px;
      margin-right: -15px;

      /* Scrollable END */
    /* Dark/dark-600 */
  
    @include mobile-breakpoint {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      width: 100%;
      max-height: calc(100vh - 475px);

      padding-right: 0px;
      margin-right: 0px;
    }
    .productSectionContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px;
      padding-bottom: 16px;
      gap: 16px;
    
      .lineDivider {
        width: 100%;
        border: 1px solid #2E2E2E;
      }
    
      .productSectionTitle {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        /* identical to box height, or 24px */
      
        display: flex;
        align-items: center;
        text-align: center;
      
        /* Light/light-700 */
      
        color: #B4B4B1;
      }
    
    }
    
    .productContainer {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 12px 16px;
      gap: 12px;
      
      width: 368px;
      
      /* Dark/dark-400 */
      
      background: #262626;
      border: 1px solid #262626;
      border-radius: 8px;
      &.selectedPlan {
        border: 1px solid #E0B23B;
      }

      @include mobile-breakpoint {
        margin: 0 19px;
      }
    }
    .unselectedIcon {
      background: #242424;
      circle {
        fill: #242424;
      }
    }
    .selectedIcon {
      background: #242424;
    }
    .productInfo {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 2px;
    
      .productTitle {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.03em;
    
        /* Light/light-700 */
    
        color: #B4B4B1;
    
        &.selectedPlan {
          color: #E0B23B;
        }
      }
      .productBullets {
        .productBullet {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0px;
          gap: 4px;
    
          .bulletIcon {
            path {
              stroke: #5C5C5C;
              fill: transparent;
            }
          }
    
          .bulletBody {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 150%;
            /* identical to box height, or 21px */
    
            display: flex;
            align-items: center;
            text-align: center;
    
            /* Dark/dark-100 */
    
            color: #5C5C5C;
    
            mix-blend-mode: normal;
          }
        }
      }
    }
  }    
  .footer {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 12px;

    @include mobile-breakpoint {
      width: 100%;
      position: absolute;
      bottom: 0;
      padding-bottom: 80px;
      background: #262626;
      padding-left: 12px;
      padding-right: 12px;
    }

    .submitButton {
      margin-top: 48px;
    
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 24px;
      /* identical to box height, or 160% */
    
      text-align: center;
      color: #131313;
    }
    
    .oldPrice {
      color: #6E5411;
      text-decoration: line-through;
      margin-right: 4px;
    }
    
    .VATWarning {
      margin-top: 12px;
    
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 150%;
      /* identical to box height, or 20px */
      
      display: flex;
      align-items: flex-end;
      text-align: center;
      
      /* Dark/dark-100 */
      
      color: #5C5C5C;
    }

    .ondemandParagraph {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      flex-wrap: nowrap;
      flex-direction: column;
    
      width: 100%;
      font-family: 'Maison Neue';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      /* or 24px */
    
      text-align: center;
    
      /* Dark/dark-100 */
    
      color:  #B4B4B1;
    
      // @include mobile-breakpoint {
      //   width: 100%;
      // }
      
      .highlighted {
        color:  #E0B23B;
        padding-right: 4px;
      }
    
      .freeplanLink {
        color: #B4B4B1;
        text-decoration: underline;
        cursor: pointer;
        padding-right: 4px;
      }
    }
  }
}
