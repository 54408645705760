
body {
  font-family: 'Inter', Helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  background-color: var(--grey100);
}

h1,h2,h3,h4 {
  font-weight: 600;
}

a {
  text-decoration: none;
  color: var(--brand);
}

