.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  width: fit-content;
  height: 41px;
  border: 1px solid #E5E7EB;
  border-radius: 24px;
  position: relative;

  span {
    font-size: 13px;
    line-height: 13px;
    text-align: center;
    color: var(--grey500);
  }

  .clearIcon {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    right: 8px;
    transform: scale(0.65);
    padding-top: 1px;
  }
}

.clearable {
  padding-right: 32px;
}
