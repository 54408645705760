@import "src/styles/v2/variables";

.icon {
  align-self: center;
  margin-bottom: 24px;
}

.subtitle {
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: var(--grey500);
  margin-bottom: 24px;
  min-width: 404px;
  align-self: center;
}

.timezoneSelect {
  margin-bottom: 24px;
  text-align: left;
  width: 100%;

  div {
    border: transparent;
  }
}