@import "variables";

// tooltip

.custom-tooltip-content {
  width: fit-content;
  padding: 16px;
  background: $dark-300;
  box-shadow: 0 0 20px #000000, 0 0 10px #131313, inset 0 0 2px rgba(243, 242, 242, 0.2);
  border-radius: 4px;
}

.custom-tooltip-arrow {
  color: $dark-300;
}

/* HubSpot Cookies Redesign */

div#hs-eu-cookie-confirmation[role=banner] {
  position: fixed;
  top: initial !important;
  bottom: 24px !important;
  background-color: $dark-500 !important;
  width: 100% !important;
  max-width: 800px !important;
  margin: 0 auto !important;
  left: 0 !important;
  right: 0 !important;
  border: none !important;
  border-radius: 6px !important;
  box-shadow: none !important;
  box-shadow: 0 0 20px #000000, 0 0 10px #131313, inset 0 0 0 .5px rgba(243, 242, 242, 0.2) !important;
}

div#hs-eu-cookie-confirmation[role=banner] div#hs-eu-cookie-confirmation-inner {
  background-color: transparent !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  padding: 12px 16px !important;
  margin: 0 !important;
  width: 100% !important;
  max-width: 100% !important;
}

div#hs-eu-cookie-confirmation[role=banner] div#hs-eu-cookie-confirmation-inner div#hs-eu-policy-wording {
  margin: 0 !important;
}

div#hs-eu-cookie-confirmation[role=banner] div#hs-eu-cookie-confirmation-inner div#hs-eu-policy-wording p {
  margin: 0 !important;
  font-family: $maison-neue !important;
  font-size: 13px !important;
  line-height: 19.5px !important;
  color: $light-600 !important;
}

div#hs-eu-cookie-confirmation[role=banner] div#hs-eu-cookie-confirmation-inner a, 
div#hs-eu-cookie-confirmation[role=banner] div#hs-eu-cookie-confirmation-inner a:hover {
  color: $light-600 !important;
}

div#hs-eu-cookie-confirmation[role=banner] div#hs-eu-cookie-confirmation-inner div#hs-en-cookie-confirmation-buttons-area {
  margin: 0 !important;
}

div#hs-eu-cookie-confirmation[role=banner] div#hs-eu-cookie-confirmation-inner div#hs-eu-cookie-confirmation-button-group {
  flex-flow: row-reverse !important;
}

div#hs-eu-cookie-confirmation[role=banner] div#hs-eu-cookie-confirmation-inner a#hs-eu-confirmation-button {
  margin: 0 !important;
  margin-left: 4px !important;
  background-color: $brand-400 !important;
  color: $brand-700 !important;
  box-shadow: 0 4px 24px rgba(224, 178, 59, 0.2), 0 2px 24px rgba(0, 0, 0, 0.05), inset 0 -1px 0px rgba(0, 0, 0, 0.5) !important;
  border: none !important;
  transition: background-color .15s !important;
  padding-right: 24px !important;
  padding-left: 24px !important;
}

div#hs-eu-cookie-confirmation[role=banner] div#hs-eu-cookie-confirmation-inner a#hs-eu-confirmation-button:hover {
  background-color: $brand-300 !important;
}

div#hs-eu-cookie-confirmation[role=banner] div#hs-eu-cookie-confirmation-inner a#hs-eu-decline-button {
  border: none !important;
  margin: 0 !important;
  color: $light-600 !important;
  transition: opacity .15s !important;
}

div#hs-eu-cookie-confirmation[role=banner] div#hs-eu-cookie-confirmation-inner a#hs-eu-decline-button:hover {
  opacity: .8 !important;
}

@include tablet-breakpoint {
  div#hs-eu-cookie-confirmation[role=banner] {
    width: calc(100% - 32px) !important;
    max-width: calc(100% - 32px) !important;
  }

  div#hs-eu-cookie-confirmation[role=banner] div#hs-eu-cookie-confirmation-inner {
    flex-flow: column !important;
  }

  div#hs-eu-cookie-confirmation[role=banner] div#hs-eu-cookie-confirmation-inner div#hs-eu-policy-wording p {
    margin-bottom: 12px !important;
  }

  div#hs-eu-cookie-confirmation[role=banner] div#hs-eu-cookie-confirmation-inner div#hs-en-cookie-confirmation-buttons-area {
    width: 100% !important;
  }

  div#hs-eu-cookie-confirmation[role=banner] div#hs-eu-cookie-confirmation-inner div#hs-eu-cookie-confirmation-button-group {
    width: 100% !important;
  }

  div#hs-eu-cookie-confirmation[role=banner] div#hs-eu-cookie-confirmation-inner a#hs-eu-confirmation-button {
    text-align: center !important;
    flex: 0 0 70% !important;
  }

  div#hs-eu-cookie-confirmation[role=banner] div#hs-eu-cookie-confirmation-inner a#hs-eu-decline-button {
    flex: 0 0 30% !important;
    text-align: center !important;
  }
}

.rhap_button-clear {
  overflow: visible !important;
}

.react-datepicker-popper {
  padding-top: 0px !important;
}

.dateTimePicker {
  border: 1px solid var(--grey300);
  border-radius: 8px;

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__current-month {
    margin-top: 8px;
    color: var(--grey500);
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 16px;
  }

  .react-datepicker__navigation {
    top: 12px;
  }

  .react-datepicker__header {
    background: none;
  }


  .react-time-picker__inputGroup__divider {
    display: none;
  }

  .react-datepicker__day {
    background: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: var(--grey500);
    height: 30px;
    width: 30px;
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
    margin: 0;
    border-radius: 0;

    &:hover {
      background: rgba(156, 163, 175, 0.51);
    }
  }

  .react-datepicker__day--in-range, .react-datepicker__day--in-selecting-range {
    background: #F3F4F6;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: var(--grey500);
  }

  .react-datepicker__day--disabled {
    background: var(--grey200);
    cursor: not-allowed;
    color: var(--grey300);

    &:hover {
      background: var(--grey200);
    }
  }

  .react-datepicker__day--range-start {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background: rgba(156, 163, 175, 0.51);
  }

  .react-datepicker__day--range-end {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background: rgba(156, 163, 175, 0.51);
  }

  .react-datepicker__day--today {
    background: var(--brand100);
    border-radius: 20px;
  }
}

.react-time-picker {
  .react-time-picker__inputGroup__input--hasLeadingZero {
    margin-left: -10px;
    padding-left: 10px;
  }

  .react-time-picker__inputGroup {
    width: fit-content;
  }

  .react-time-picker__clear-button {
    display: none;
  }

  .react-time-picker__wrapper {
    min-width: 132px;
    width: 100%;
    padding: 12px;
    margin-top: 4px;
    margin-bottom: 4px;
    border-radius: var(--radius-medium);
    border: 1px solid var(--grey300);
    outline: none;
  }

  input, select {
    font-size: var(--fontsize-14);
    color: var(--grey800);
    outline: none;
  }

  input:focus {
    background-color: var(--white);
    border-radius: var(--radius-medium);
    outline: none;
    border-color: var(--brand);
  }

  .react-time-picker__inputGroup__leadingZero {
    font-family: 'Inter';
    font-style: normal;
    font-size: 14px;
  }
}
