@import "src/styles/v2/variables";

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;


  border-radius: 6px;
  background: #131313;

  border: 1px solid #5C5C5C;

  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 32px;
  padding-right: 32px;

  @include mobile-breakpoint {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 24px;
    gap: 24px;
    
    position: absolute;
    width: 343px;
  }

}

.column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;

  @include mobile-breakpoint {
    align-items: center;
  }

  .rowTitle {
    color: #E0B23B;
    font-family: 'Maison Neue';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
  }

  .subtitle {
    font-family: 'Maison Neue';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    
    /* Dark/dark-100 */
    
    color: #5C5C5C;
    
    mix-blend-mode: normal;
  }
}

.onDemandBenefits {
  display: flex;
  flex-direction: row;
  gap: 24px;

  @include mobile-breakpoint {
    align-items: center;
    flex-direction: column;
    gap: 0px;
  }

  .onDemandBenefit {
    display: flex;
    align-items: center;
    gap: 8px;

    @include mobile-breakpoint {
      justify-content: center
    }

    .checkIcon {
      path {
        stroke: #5C5C5C;
      }
    }

    span {
      font-family: 'Maison Neue';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
      text-align: center;
      
      /* Light/light-700 */
      
      color: #B4B4B1;
    }
  }
}

.ctaColumn {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 6px;

  .button {
    cursor: pointer;
    width: 100%;
  }

  .actionSubtitle {
    color: #5C5C5C;
    font-family: "Maison Neue", Helvetica, sans-serif;
    font-size: 12px;
    line-height: 24px;
    font-weight: 400;
    text-align: center;
  }
}

.col {
  display: flex;
  flex-direction: column;
}
