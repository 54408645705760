.button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  border-radius: 8px;
  font-size: 14px;
  text-align: center;
  border: 1px solid var(--grey200);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
  background: #fff;
  font-weight: 500;
  color: var(--grey500);
  cursor: pointer;
  width: 109px;
  height: 49px;

  &:hover {
    border-color: var(--grey100);
  }
}