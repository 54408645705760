/*
 * Input Component 
 */

.label {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: var(--grey800);
  text-align: left;
}

.input {
  display: block;
  width: 100%;
  padding: 12px;
  min-height: 41px;
  margin-top: 8px;
  background-color: var(--white);
  border-radius: var(--radius-medium);
  font-size: var(--fontsize-16);
  color: var(--grey800);
  border: 1px solid var(--grey200);
  outline: none;
}

.input:focus {
  background-color: var(--white);
  border-radius: var(--radius-medium);
  outline: none;
  border-color: var(--brand);
}

