/*
 * Profile form
*/

.profileBasic__avatar {
  position: relative;
  width: 72px;
  height: 72px;
  border-radius: 100%;
  overflow: hidden;
}

.profileBasic__avatar img {
  width: 100%;
}

.profileBasic__avatar:hover .profileBasic__avatarIcon {
  display: inline-flex;
  cursor: pointer;
}

.profileBasic__avatarIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(222, 97, 57, 0.9);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  display: none;
  cursor: pointer;
}

.profileBasic__avatarInput {
  display: none;
}

/*
 * Input Component
 */

 .label {
  display: block;
  font-size: 16px;
  color: var(--grey700);
  position: relative;
 }

 .error {
   font-size: 14px;
   color: var(--danger);
   position: absolute;
   left: 0;
   bottom: -24px;
 }

.label.mtMedium {
  margin-top: 24px;
}

.input {
  display: block;
  width: 100%;
  padding: 16px 24px;
  margin-top: 4px;
  margin-bottom: 32px;
  background-color: var(--input-background);
  border-radius: var(--radius-medium);
  font-size: var(--fontsize-16);
  color: var(--grey800);
  border: 1px solid var(--grey300);
  outline: none;
}

.input:focus {
  background-color: var(--white);
  border-radius: var(--radius-medium);
  outline: none;
  border-color: var(--brand);
}

.inputDisabled {
  cursor: not-allowed;
  background-color: var(--grey100);
}

.timezoneContainer {
  margin-bottom: 32px;
}