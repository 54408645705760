/*
 * Button Component 
 */


 .button {
  /*width: 100%;*/
  padding: var(--button-padding);
  background-color: var(--button-background);
  border-radius: var(--button-radius);
  font-size: var(--fontsize-14);
  box-shadow: var(--button-boxShadow);
  color: var(--button-textColor);
  text-align: center;
  cursor: pointer;
  outline: none;
  transition: background-color .15s;
}

.container {
 display: flex;
 align-items: center;
 justify-content: space-between;
 height: 100%;
}

.button:hover {
  background-color: #E06C47;
}

.button.stretch {
  width: 100%;
  padding: var(--space-16) var(--space-24);
}

.button:disabled {
 cursor: not-allowed;
 background-color: var(--grey300);
 box-shadow: none;
}
