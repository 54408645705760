@import "src/styles/v2/variables";

$images-z-index: 1000;
$backdrop-z-index: 2000;
$content-z-index: 3000;


.container {
  background: $dark-700;
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  align-items: center;
  overflow: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .leftImage, .rightImage {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    left: 0;
    height: 100%;
    overflow: hidden;
    width: 30%;

    &:after {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(0deg, rgba(19, 19, 19, 0.9), rgba(19, 19, 19, 0.9)), linear-gradient(90deg, #131313 0%, rgba(19, 19, 19, 0.991353) 6.67%, rgba(19, 19, 19, 0.96449) 13.33%, rgba(19, 19, 19, 0.91834) 20%, rgba(19, 19, 19, 0.852589) 26.67%, rgba(19, 19, 19, 0.768225) 33.33%, rgba(19, 19, 19, 0.668116) 40%, rgba(19, 19, 19, 0.557309) 46.67%, rgba(19, 19, 19, 0.442691) 53.33%, rgba(19, 19, 19, 0.331884) 60%, rgba(19, 19, 19, 0.231775) 66.67%, rgba(19, 19, 19, 0.147411) 73.33%, rgba(19, 19, 19, 0.0816599) 80%, rgba(19, 19, 19, 0.03551) 86.67%, rgba(19, 19, 19, 0.0086472) 93.33%, rgba(19, 19, 19, 0) 100%);
      transform: matrix(-1, 0, 0, 1, 0, 0);
      z-index: 1;
    }

    & > div {
      height: 100%;
    }
  }

  .leftImage .bg,
  .rightImage .bg {
    width: 100%;
    height: 100%;
    background-image: url('../../../assets/images/signup-flow/left.webp');
    background-position: left bottom;;
    background-repeat: repeat-y;
    background-size: contain;
  }

  .rightImage .bg { 
    background-image: url('../../../assets/images/signup-flow/right.webp');
    background-position: right bottom;
  }

  .rightImage {
    left: initial;
    right: 0;

    &:after {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      background: linear-gradient(0deg, rgba(19, 19, 19, 0.9), rgba(19, 19, 19, 0.9)), linear-gradient(90deg, #131313 0%, rgba(19, 19, 19, 0.991353) 6.67%, rgba(19, 19, 19, 0.96449) 13.33%, rgba(19, 19, 19, 0.91834) 20%, rgba(19, 19, 19, 0.852589) 26.67%, rgba(19, 19, 19, 0.768225) 33.33%, rgba(19, 19, 19, 0.668116) 40%, rgba(19, 19, 19, 0.557309) 46.67%, rgba(19, 19, 19, 0.442691) 53.33%, rgba(19, 19, 19, 0.331884) 60%, rgba(19, 19, 19, 0.231775) 66.67%, rgba(19, 19, 19, 0.147411) 73.33%, rgba(19, 19, 19, 0.0816599) 80%, rgba(19, 19, 19, 0.03551) 86.67%, rgba(19, 19, 19, 0.0086472) 93.33%, rgba(19, 19, 19, 0) 100%);
      transform: matrix(1, 0, 0, -1, 0, 0);
      z-index: 2;
    }
  }

  h1 {
    @include headline-medium;
    color: $light-300;
    margin: 112px auto 24px auto;
    white-space: break-spaces;
    text-align: center;
    z-index: $content-z-index;
  }

  .infoText, .instructionsSent {
    @include text-medium;
    text-align: center;
    white-space: break-spaces;
    color: $light-700;
    width: 85%;
    margin: 0 auto;
    z-index: $content-z-index;
  }

  .instructionsSent {
    margin-top: 1rem;
    width: 100%;
    text-align: left;
    white-space: initial;
    border: 1px solid $brand-600;
    border-radius: .4rem;
    padding: 1rem;
    background-color: rgba(53, 43, 18, 0.05);
    color: $light-500;
  }

  .form {
    display: flex;
    flex-flow: column;
    width: 360px;
    margin: 0 auto;
    z-index: $content-z-index;

    .button {
      margin-top: 16px;
    }

    .backToLogin {
      @include text-medium;
      color: $light-300;
      margin-top: 24px;
      margin-bottom: 4rem;
      text-align: center;
    }
  }
}

@include tablet-breakpoint {
  .container {
    &:before {
      content: " ";
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: $dark-700;
      z-index: $backdrop-z-index;
    }

    .leftImage,
    .rightImage {
      animation: none;
    }

    .form {
      width: 100%;
      padding: 0 1.6rem;
    }

    .button {
      margin-top: 24px;
      margin-bottom: 24px;
    }

    .loginMobile {
      display: block;
      margin-bottom: 64px;
      color: $light-500;
      text-align: center;
    }
  }
}
