.modal.modal {
  width: 474px;
  height: fit-content;
  padding: 24px;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  overflow: visible;

  .title {
    margin-top: 12px;
    color: var(--grey500);
    font-size: 14px;
  }

  .timezoneSelect {
    margin-top: 23px;
    text-align: left;
    width: 100%;
  }

  .button {
    margin-top: 23px;
    align-self: flex-end;
  }
}