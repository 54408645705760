.uploadWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  &:last-of-type {
    margin-bottom: 0;
  }

  .upload {
    width: calc(100% - 72px);
    flex: 1;
    height: 28px;
    border-radius: 4px;
    position: relative;
    border: 1px solid var(--grey400);
    background: rgba(245, 245, 245, 0.5);

    .progress {
      position: absolute;
      height: 100%;
      width: 0;
      background: var(--grey400);
      border-radius: 4px;
      z-index: -1;
    }

    .content {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 8px;
      font-size: 14px;
      color: var(--grey800);
      border-radius: 4px;

      .fileName {
        max-width: 40%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .bgFailed {
    background-color: #FCF3F0;
  }

  .bgCompleted {
    background-color: #F0F6F6;
  }

  .description {
    margin-left: 8px;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    width: 64px;
    justify-content: flex-end;

    .completed {
      color: var(--success);
    }

    .failed {
      color: var(--danger);
    }

    .cancel {
      color: var(--danger);
      font-weight: 400;
      cursor: pointer;

      &:hover {
        color: #C53939A6;
      }
    }
  }
}