/*
 * Profile Notifications
*/

.profileNotifications__group {
  margin-bottom: 40px;
}

.profileNotifications__title {
  margin-bottom: 16px;
  color: var(--grey400);
  text-transform: uppercase;
  font-weight: 400;
}

.profileNotifications__item {
  padding-bottom: 12px;
  border-bottom: 1px solid var(--grey200);
}

.profileNotifications__item + .profileNotifications__item {
  padding-top: 12px;
}

.toggleContainer {
  display: flex;
  align-items: center;
  color: var(--grey700)
}

.profileNotifications__requiredItem {
  margin-right: 12px;
  margin-left: auto;
  color:  var(--grey400);
}

.profileNotifications__comingSoonItem {
  margin-right: 0;
  margin-left: auto;
  text-align: right;
  color:  var(--grey400);
}


.toggle {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 40px;
  min-width: 40px;
  height: 24px;
  margin-right: 12px;
  margin-left: auto;
  display: inline-block;
  position: relative;
  border-radius: 50px;
  overflow: hidden;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #D9D9D9;
  transition: background-color ease 0.3s;
}

.toggle:before {
  content: " ";
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  background: #fff;
  left: 2px;
  top: 2px;
  border-radius: 50%;
  color: #fff;
  box-shadow: 0 1px 2px rgba(0,0,0,0.2);
  transition: all cubic-bezier(0.3, 1.5, 0.7, 1) 0.3s;
}

.toggle:checked {
  background-color: var(--brand);
}

.toggle:checked:before {
  left: 18px;
}