.bannersContainer {
  position: sticky;
  top: 0;
  z-index: 2000;

  .paymentFailedContainer, .container {
    width: 100%;
    min-height: 48px;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--success);

    div, p {
      color: var(--grey100);
      font-size: 16px;
      text-align: center;
      padding: 8px;

      u, a {
        color: white;
        font-weight: 600;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .paymentFailedContainer {
    background: var(--danger);
  }
}
