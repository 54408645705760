@import "src/styles/v2/variables";

.container {
  display: flex;
  flex-flow: column;
  padding: 24px;
  border-radius: 8px;
  background-color: var(--white);
  box-shadow: 0 2px 16px rgb(14 15 57 / 5%), 0 2px 8px rgb(14 15 57 / 5%);

  .textContainer {
    margin-bottom: 24px;

    .title {
      font-size: 18px;
      font-weight: 500;
      line-height: 19px;
      text-align: center;
      margin-bottom: 16px;
    }

    .description {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
      color: var(--grey500);
      width: 65%;
      margin: auto;

      @include tablet-breakpoint {
        width: 80%;
      }

      @include mobile-breakpoint {
        width: 90%;
      }
    }
  }

  .faq {
    background-color: red;
  }
}
