/*
 * Avatar Component 
 */
 
 .avatar {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  overflow: hidden;
}

.avatarImg {
  width: 100%;
  max-width: 100%;
}
