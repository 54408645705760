@import "src/styles/v2/variables";

/*
 * Facebook Login Button
 */

.button {
  position: relative;
  width: 100%;
  padding: var(--button-padding);
  background-color: $facebook;
  box-shadow: 0 4px 24px rgba(24, 119, 242, 0.2), 0 2px 24px rgba(0, 0, 0, 0.05), inset 0px -1px 0px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  @include text-headline;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  font-size: var(--fontsize-16);
  font-weight: $font-weight-bold;
  letter-spacing: .05rem;
  color: $light-100;
  text-align: center;
  cursor: pointer;
  outline: none;
  margin-bottom: 12px;
}

.buttonIcon {
  display: flex;
  align-items: center;
  position: absolute;
  height: fit-content;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 16px;
}