.invitations {
  margin-top: 16px;
  display: grid;
  width: 100%;
  min-width: 400px;
  height: fit-content;
  grid-template-columns: 5fr 3fr 3fr;
  grid-gap: 16px;

  h4 {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
    width: 100%;
  }

  span {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
  }
}


.container {
  margin-bottom: 36px;
}

.reminder {
  width: 140px;
  height: 24px;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  text-align: center;
  color: var(--grey500);
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    width: 100%;
  }

  button {
    width: 100%;
    height: 100%;
    outline: none;
    border: 1px solid var(--grey200);
    border-radius: 8px;
    cursor: pointer;
    background-color: inherit;

    &:hover {
      background-color: white;
      opacity: 0.9;
    }
  }
}
