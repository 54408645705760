@import "src/styles/v2/variables";
/*
 * My Sessions
 */

/* Navbar */
.navbar {
  display: flex;
  height: 80px;
  justify-content: space-between;
  background-color: var(--grey900);

}

.navbarNav {
  height: 100%;
}

.navbarNavLink {
  display: inline-flex;
  position: relative;
  align-items: center;
  height: 100%;
  color: var(--white);
  opacity: .5;
}

.navbarNavLink + .navbarNavLink {
  margin-left: 32px;
}

.navbarNavLink.isActive {
  opacity: 1;
}

.navbarNavLink.isActive:after {
  content: " ";
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 4px;
  width: 100%;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
  background-color: var(--brand);
}

.wrapper {
  background-color: var(--grey100);
}


/* Header */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.headerNav {
  position: relative;
  padding: 16px 24px 8px 24px;
  background-color: var(--white);
  box-shadow: 0px 2px 8px rgba(14, 15, 57, 0.1);
  overflow-wrap: break-word;
}

/* Header SubMenu*/

.headerSubMenuLink {
  display: inline-block;
  margin-bottom: 8px;
  padding: 4px 12px;
  border-radius: 20px;
  border: 1px solid var(--grey100);
  font-size: 14px;
  color: var(--grey600);
  cursor: pointer;
  overflow-wrap: break-word;
}

.headerSubMenuLink:hover {
  border-color: var(--grey300);
  color: var(--grey700);
}

.headerSubMenuLink.isActive {
  color: var(--brand);
  border-color: var(--brand)
}

.headerSubMenuLink + .headerSubMenuLink {
  margin-left: 4px;
}

/* Button */
.button {
  width: 100%;
  padding: var(--button-padding);
  background-color: var(--button-background);
  border-radius: var(--button-radius);
  font-size: var(--fontsize-14);
  box-shadow: var(--button-boxShadow);
  color: var(--button-textColor);
  text-align: center;
  cursor: pointer;
  outline: none;
}

.button.fixed {
  width: initial;
  padding: var(--space-16) var(--space-24);
}

.musiciansGrid {
  max-width: 1024px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 24px auto 0 auto;
  grid-auto-rows: min-content;

  @media only screen and (max-width: 1366px)  {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: 948px)  {
    grid-template-columns: 1fr;
  }
}

.musiciansGrid__notFound {
  max-width: 300px;
  margin: 120px auto 0 auto;
  text-align: center;
}

.musiciansGrid__notFoundIcon {
  margin-bottom: 8px;
}

.musiciansGrid__notFoundTitle {
  color: var(--grey700);
  margin-bottom: 8px;
}

.musiciansGrid__notFoundDescription {
  color: var(--grey500);
}

.spinner {
  transform: scale(2.5);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

@media (max-width: 992px) {
  .musiciansGrid {
    justify-content: center;
  }
}

.proExploreContainer {
  display: flex;

  @include tablet-breakpoint {
    .musiciansGrid {
      margin-top: 96px;
    }

    .filtersApplied {
      margin-top: 0;
    }
  }

  @include mobile-breakpoint {
    flex-direction: column;
  }

  .musiciansGrid {
    justify-content: center;
  }

  .noAvailableSessionContainer {
    width: 381px;
    height: fit-content;
    margin: 240px auto 0 auto;
    background: var(--white);
    box-shadow: 0 2px 16px rgba(14, 15, 57, 0.05), 0 2px 8px rgba(14, 15, 57, 0.05);
    border-radius: 8px;
    grid-column: 100;
    padding: 24px;
    display: flex;
    flex-flow: column;
    align-items: center;

    @include mobile-breakpoint {
      margin: 16px;
      width: calc(100% - 32px);
    }

    p {
      color: var(--grey500);
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      margin-top: 24px;
    }
  }
}

.billboard {
  justify-content: center;
  margin-right: 16px;
  margin-left: 16px;
  margin-bottom: 24px;
}

.exploreBody {
  max-width: 1024px;
  display: grid;
  margin: 24px auto 0 auto;
  grid-auto-rows: min-content;
}