.container {
  cursor: pointer;
}

.icon {
  display: flex;
  flex: 1;
  background-color: var(--brand);
  align-self: stretch;
  align-items: center;
  justify-content: center;
}
