@import "src/styles/v2/variables";

.paymentOptionsContainer {
  width: 100%;
  height: 55px;
  margin: 16px 0 8px 0;
  background: rgba(71, 71, 71, 0.33);
  border-radius: 4px;
  position: relative;
  box-shadow: 0 8px 8px -8px #131313, 0 4px 4px #131313, 0 2px 2px #131313, inset 0 0 2px rgba(235, 234, 231, 0.5);
  display: flex;

  .paymentOption {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    cursor: pointer;

    div {
      display: flex;
      align-items: center;
    }

    .checkbox {
      border: 1px solid $dark-200;
      width: 16px;
      height: 16px;
      border-radius: 50%;
    }

    span {
      @include text-small;
      color: $light-300;
      margin-left: 8px;
    }
  }

  .paymentOptionSelected {
    cursor: auto;
    background: rgba(224, 178, 59, 0.1);
    box-shadow: 0 8px 8px rgba(19, 19, 19, 0.15), 0 4px 4px rgba(19, 19, 19, 0.15), 0 2px 2px rgba(19, 19, 19, 0.15), inset 0 0 2px #E0B23B;
    border-radius: 4px;

    .checkboxChecked {
      border: 1px solid $brand-400;
      width: 16px;
      height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;

      &:after {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: $brand-400;
      }
    }
  }
}

.customInputContainer {
  margin-top: 16px;
  display: flex;
  flex-flow: column;

  label {
    display: flex;
    flex-flow: column;
    @include text-medium;
    line-height: 19.41px;
    color: $light-700;
    letter-spacing: 0.16px;
    margin-bottom: 4px;
  }

  .customInput {
    background: rgba(71, 71, 71, 0.33);
    box-shadow: 0 8px 8px -8px rgba(19, 19, 19, 0.15), 0 4px 4px rgba(19, 19, 19, 0.15), 0 2px 2px rgba(19, 19, 19, 0.15), inset 0 0 2px rgba(235, 234, 231, 0.5);
    border-radius: 4px;
    padding: 16px;
    color: $light-300;
    @include text-medium;
    letter-spacing: 0.16px;
    outline: none;

    &:focus {
      background: $dark-400;
      box-shadow: 0 8px 8px -8px rgba(19, 19, 19, 0.15), 0 4px 4px rgba(19, 19, 19, 0.15), 0 2px 2px rgba(19, 19, 19, 0.15), inset 0 0 0 1px #E0B23B;
    }
  }
}

.stripeInput.stripeInput {
  select, input {
    border: none;
    background: rgba(71, 71, 71, 0.33);
    box-shadow: 0 8px 8px -8px rgba(19, 19, 19, 0.15), 0 4px 4px rgba(19, 19, 19, 0.15), 0 2px 2px rgba(19, 19, 19, 0.15), inset 0 0 2px rgba(235, 234, 231, 0.5);
    border-radius: 4px;
    padding: 16px;
    color: $light-300;
    @include text-medium;
    letter-spacing: 0.16px;
    outline: none;
    font-size: 16px;
    font-family: sans-serif;

    &:focus {
      box-shadow: 0 8px 8px -8px rgba(19, 19, 19, 0.15), 0 4px 4px rgba(19, 19, 19, 0.15), 0 2px 2px rgba(19, 19, 19, 0.15), inset 0 0 2px rgba(235, 234, 231, 0.5);
    }
  }

  select {
    border: 1px solid rgba(235, 234, 231, 0.15);
  }
}

.customMergedInputs {
  display: flex;
  flex-flow: row;

  .customInputContainer {
    width: 50%;

    &:first-of-type {
      .customInput {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &:last-of-type {
      .customInput {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}

.paypalContainer {
  margin-top: 24px;
}

.couponContainer {
  margin-top: 24px;

  .haveACoupon {
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      margin-left: 8px;
      @include text-small;
      line-height: 14px;
      color: $brand-400;
      position: relative;
      top: 2px;
    }
  }

  .couponInputContainer {
    border-top: 1px solid $dark-500;

    .couponInput {
      .apply {
        cursor: pointer;
        padding-top: 16px;
        @include text-small;
        line-height: 14px;
        color: $brand-400;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 16px;
        margin: auto;
      }

      .couponSpinner {
        transform: scale(0.5);
        position: absolute;
        top: 0;
        bottom: 0;
        right: 8px;
        margin: auto;
      }
    }
  }

  .validCouponContainer {
    display: flex;
    position: relative;

    .descriptions {
      margin-left: 12px;
      display: flex;
      flex-flow: column;

      p {
        @include text-medium;
        line-height: 15px;
        color: $light-400;
        margin-bottom: 4px;
      }

      span {
        @include text-small;
        line-height: 15px;
        color: $brand-500;
      }
    }

    .remove {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 12px;
      line-height: 15px;
      color: $brand-400;
      cursor: pointer;
    }
  }
}

.buttonContainer {
  margin-top: 24px;
  max-width: 360px;

  @include mobile-breakpoint {
    width: 100%;
    max-width: 400px;
  }
}

.bullets {
  margin-top: 24px;
  margin-bottom: 64px;
  display: flex;
  flex-flow: column;

  > div {
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    .tick {
      position: relative;
      top: -1px;
    }

    span {
      margin-left: 8px;
      color: $light-700;
      @include text-small;

      .crossed {
        text-decoration: line-through;
        margin: 0;
      }
    }
  }
}

.secureMessageContainer {
  margin-top: .8rem;
  display: flex;
  justify-content: center;
}

.secureMessage {
  display: inline-flex;
  width: 100%;
  padding: .4rem .8rem;
  border-radius: 4px;
  background-color: rgba(61, 175, 93, 0.05);
  //background-color: transparent;
  align-items: center;
  justify-content: center;
}

.secureMessageIcon {
  display: inline-flex;
  height: 1.6rem;
  align-items: center;
  margin-right: .4rem;
  
  svg {
    filter: drop-shadow(0 0 4px rgba(61, 175, 93, 0.3))
  }
}

.secureMessageText {
  color: #3DAF5D;
  line-height: 1.6rem;
  font-size: .8rem;
  font-family: $maison-neue;
}
