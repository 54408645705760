@import "src/styles/v2/variables";

/*
 * Session Form
 */

 .sessionForm {
  width: 100%;
}

/* Header */
.sessionForm__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 32px;
  background-color: var(--grey100);
}

@media (max-width: 576px) {
  .sessionForm__header {
    padding: 12px 16px;
  }
}

.sessionForm__headerTitle {
  color: var(--grey800);
}

.sessionForm__headerButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: var(--white);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.03);
  cursor: pointer;
}

.sessionForm__headerMusician {
  display: flex;
  align-items: center;
}

.sessionForm__headerMusicianAvatar {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 12px;

  @include mobile-breakpoint {
    width: 38px;
    height: 38px;
  }
}

.sessionForm__headerMusicianAvatar img {
  max-width: 100%;
}

.sessionForm__headerMusicianInstrument {
  font-size: 13px;
  line-height: 15px;
  font-weight: 500;
  color: var(--grey500);
}

.sessionForm__headerMusicianName {
  font-size: 18px;
  line-height: 20px;
  color: var(--grey800);
  font-weight: 500;

  @include mobile-breakpoint {
    font-size: 14px;
    line-height: 16px;
  }
}

.sessionForm__headerFullProfile {
  color: var(--grey700);

  @include mobile-breakpoint {
    display: none;
  }
}

@media (max-width: 576px) {
  .sessionForm__headerFullProfile {
    font-size: 14px;
  }
}

.sessionForm__headerFullProfile:hover {
  color: var(--grey500);
}

.sessionForm__headerFullProfile:hover svg path {
  stroke: var(--grey400);
}

.sessionForm__headerFullProfile svg {
  margin-left: 8px;
}

/* Sub Header – Form navigation */
.sessionForm__subHeader {
  display: flex;
  padding: 0 40px;
}

@media (max-width: 576px) {
  .sessionForm__subHeader {
    padding: 0 12px;
  }
}

.sessionForm__subHeaderItem {
  width: 33%;
  padding: 0;
  border-bottom: 2px solid var(--grey100);
  color: var(--grey400);
  font-size: 14px;
}

@media (max-width: 576px) {
  .sessionForm__subHeaderItem {
    display: none;
    padding: 0;
    margin-bottom: 8px;
  }
}

.sessionForm__subHeaderItem.isActive {
  color: var(--brand);
  border-color: var(--brand);

  @include mobile-breakpoint {
    width: 100%;
    display: block;
    text-align: center;
  }
}

.sessionForm__subHeaderItem a {
  display: block;
  padding: 12px 0;
  color: inherit;
}

.sessionForm__body {
  padding: 16px 40px;
}

@media (max-width: 576px) {
  .sessionForm__body {
    padding: 16px 12px;
  }
}

.sessionForm__bodyForm {
  height: calc(100vh - 248px);
  padding: 0 8px 24px 8px;
  overflow-y: scroll;

  @include mobile-breakpoint {
    height: calc(100vh - 224px);
  }
}

.sessionForm__subHeader.inEditMode .sessionForm__subHeaderItem {
  width: 33.3333%;
  text-align: left;

  @include mobile-breakpoint {
    width: 50%;
    text-align: center;
  }
}


/* Session Actions */
.sessionForm__actions {
  text-align: center;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  right: 0;
  left: 0;
  background: #fff;
  box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.05), 0px 24px 64px rgba(0, 0, 0, 0.05);
  padding: 16px 40px;
  bottom: 0;
}

@media (max-width: 576px) {
  .sessionForm__actions {
    padding: 12px 16px;
  }

  .sessionForm__actionsButton {
    margin-left: 16px;
  }
}

.sessionForm__actionsButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 16px 0;
  border-radius: 8px;
  background-color: var(--grey100);
  font-size: 14px;
  text-align: center;
  color: var(--grey400);
  cursor: not-allowed;
  font-weight: 400;
  box-shadow: none;
  transition: background-color .3s;
}

.sessionForm__saveButton {
  min-width: 120px;
}

@media (max-width: 576px) {
  .sessionForm__actionsButton {
    width: initial;
    padding-left: 16px;
    padding-right: 16px;
    flex-shrink: 0;
  }
}

.sessionFrom__actionsButtonIcon {
  margin-left: 16px;
  display: flex;
  align-items: center;
}

.sessionFrom__actionsButtonIcon svg path {
  stroke: var(--grey200);
}

.sessionForm__actions.isActive .sessionForm__actionsButton {
  cursor: pointer;
  background-color: var(--brand);
  color: var(--white);
  box-shadow: var(--button-boxShadow);
  min-width: 120px;
}

.sessionForm__actions.isActive .sessionForm__actionsButton:hover {
  opacity: 0.9;
}

.sessionForm__actions.isActive .sessionFrom__actionsButtonIcon svg path {
  stroke: var(--white);
}

@media (min-width: 576px) {
  .sessionForm__actionsButtonOutlineCancel {
    display: none !important;
  }
}


.isEditTab {
  cursor: pointer;
  display: block;
}


/* Row of items */
.sessionForm__row {
  display: flex;
  justify-content: space-between;
}

.sessionForm__row .label {
  width: calc(50% - 24px);

  @include mobile-breakpoint {
    width: calc(50% - 8px);
  }
}

.sessionForm__actionsSecondaryGroup {
  display: flex;
  align-items: center;
}

@media (max-width: 576px) {
  .sessionForm__actionsSecondaryGroup {
    flex-shrink: 0;
  }
}

.sessionForm__actionsbuttonLink {
  color: var(--grey500);
  margin-right: 24px;
  cursor: pointer;
}

@media (max-width: 576px) {
  .sessionForm__actionsbuttonLink {
    margin-right: 0;
  }
}

.sessionForm__actionsbuttonLink:hover {
  color: var(--grey700);
}

/* Form actions with mulitple buttons */
.sessionForm__actionsMultiple {
  display: flex;
}

.sessionForm__actionsButtonOutline {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
  border-radius: 8px;
  background-color: transparent;
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  border: 1px solid var(--grey200);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
  background: #fff;
  color: var(--grey500);
  cursor: pointer;
  padding-left: 16px;
  padding-right: 16px;
}

@media (max-width: 576px) {
  .sessionForm__actionsButtonOutline {
    flex-shrink: 0;
  }
}

.sessionForm__actionsMultiple .sessionForm__actionsButton {
  width: initial;
  padding-left: 16px;
  padding-right: 16px;
}

/* Radio Buttons */
.sessionForm__radioInput {
  margin-bottom: 12px;
}

.sessionForm__radioInput [type="radio"]:checked,
.sessionForm__radioInput [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.sessionForm__radioInput [type="radio"]:checked + label,
.sessionForm__radioInput [type="radio"]:not(:checked) + label {
  position: relative;
  padding: 16px;
  padding-left: 44px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
  background-color: var(--grey50);
  border: 1px solid var(--grey50);
  width: 100%;
  border-radius: 8px;
}

.sessionForm__radioInput [type="radio"]:checked + label:before,
.sessionForm__radioInput [type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 16px;
  top: 16px;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}

.sessionForm__radioInput [type="radio"]:checked + label {
  border: 1px solid var(--brand);
  background-color: var(--white);
}

.sessionForm__radioInput [type="radio"]:checked + label:after,
.sessionForm__radioInput [type="radio"]:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: var(--brand);
  border-color: var(--brand);
  position: absolute;
  top: 20px;
  left: 20px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.sessionForm__radioInput [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.sessionForm__radioInput [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.sessionForm__radioInputGroup {
  display: flex;
  flex-wrap: wrap;
}

.sessionForm__radioInputGroup .sessionForm__radioInput {
  margin-right: 8px;
}

/* Additional Notes */
.sessionForm__notes {
  display: block;
  position: relative;
}

.sessionForm__notes textarea {
  width: 100%;
  background-color: var(--grey50);
  border: 1px solid var(--grey50);
  border-radius: 8px;
  margin: 0;
  box-sizing: border-box;
  height: 120px;
}

/* Sub titles */

.sessionForm__subTitle {
  color: var(--grey700);
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 8px;
}

.sessionForm__subTitleSpaceTop {
  margin-top: 32px;
}

.sessionForm__subDescription {
  margin-bottom: 16px;
  color: var(--grey500);
  font-size: 14px;
}

/*======= Temporary ======*/
.link {
  color: var(--brand);
}

.label {
  display: block;
  color: var(--grey700);
}

.labelInline {
  display: block;
}

.input {
  display: block;
  width: 100%;
  padding: var(--input-padding);
  background-color: var(--input-background);
  border-radius: var(--radius-medium);
  font-size: var(--fontsize-16);
  color: var(--grey800);
  outline: none;
}

.input:focus {
  background-color: var(--white);
  border-radius: var(--radius-medium);
  outline: none;
  box-shadow: 0 0 0 2px var(--brand);
}

.button {
  width: 100%;
  padding: var(--button-padding);
  background-color: var(--button-background);
  border-radius: var(--button-radius);
  font-size: var(--fontsize-16);
  box-shadow: var(--button-boxShadow);
  color: var(--button-textColor);
  text-align: center;
  cursor: pointer;
  outline: none;
}

.buttonFacebook {
  background-color: var(--button-backgroundFacebook);
  box-shadow: var(--button-boxShadowFacebook);
}

.buttonGoogle {
  background-color: var(--button-backgroundGoogle);
  box-shadow: var(--button-boxShadowGoogle);
}


/* Thank */

.confirmation {
  max-width: 62%;
  margin: 16% auto 0 auto;
  text-align: center;
  display: flex;
  flex-flow: column;
  align-items: center;
}

@media (max-width: 576px) {
  .confirmation {
    max-width: calc(100% - 32px);
  }
}

.confirmation__icon {
  margin-bottom: 24px;
}

.confirmation__title {
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 700;
}

.confirmation__fileUploadReminderWrapper {
  margin-bottom: 32px;
}

.uploadButton {
  width: 380px;
  margin-bottom: 24px;
  height: 51px;
}

.confirmationFilesUploadReminder {
  font-size: 18px;
  line-height: 22px;
}

.confirmation__description {
  margin-bottom: 8px;
  padding: 0 36px;
  color: #6B7280;

  @include mobile-breakpoint {
    padding: 0;
  }
}

.uploadInfo {
  color: var(--grey400);
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  max-width: 400px;
  margin: 0 auto 64px auto;

  padding-left: 50px;
  padding-right: 50px;
}

.editSessionDetailsText {
  margin-top: 32px;
  color: var(--grey500);
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
}

.confirmation__exitLink {
  display: block;
}

@media (max-width: 576px) {
  .confirmation__exitLink {
    padding: var(--button-padding);
    background-color: var(--button-background);
    border-radius: var(--button-radius);
    font-size: var(--fontsize-14);
    box-shadow: var(--button-boxShadow);
    color: var(--button-textColor);
    text-align: center;
    cursor: pointer;
    outline: none;
    transition: background-color .15s;
  }
}

.customSelect {
  margin-bottom: 24px;
}

.control {
  div {
    border-color: transparent;
  }
}

.sampleRateContainer {
  width: calc(50% - 24px);

  @include mobile-breakpoint {
    width: calc(50% - 8px);
  }

  .sampleRate {
    color: var(--grey700);

    span {
      margin-top: 2px;
      line-height: 14px;
      font-size: 14px;
    }
  }
}


/* Spacing */

.mbMin {
  margin-bottom: var(--space-8);
}

.mbSmall {
  margin-bottom: var(--space-16);
}

.mbMedium {
  margin-bottom: var(--space-24);
}

.mbLarge {
  margin-bottom: var(--space-40);
}

.closeIcon {
  display: none;

  @include mobile-breakpoint {
    display: block;
  }
}
