@import "src/styles/v2/variables";

.title {
  color: var(--grey600);
  font-size: 16px;
  line-height: 17px;
  text-align: left;
  margin-bottom: 8px;
}

.container {
  border: 2px solid #F9FAFB;
  border-radius: 8px;
  padding: 16px 24px;
  display: flex;
  flex-flow: column;
  margin-bottom: 8px;
  transition: background-color .15s;

  .header {
    font-size: 16px;
    line-height: 17px;
    text-align: left;
    color: var(--grey600);
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    span {
      width: calc(100% - 24px);
    }

    .closeIcon {
      transform: rotate(45deg);
      transition-duration: 0.2s;
      transition-property: transform;
    }

    .closeIconRevert {
      transform: rotate(0deg);
      transition-duration: 0.2s;
      transition-property: transform;
    }
  }

  .content {
    color: var(--grey600);
    font-size: 16px;
    line-height: 18px;
    text-align: left;
    max-height: 0;
    overflow: hidden;
    transition: 0.2s ease-out;
    will-change: max-height;
    padding-right: 24px;
    overflow-x: auto;
  }

  &:hover {
    .header {
      opacity: 0.8;
    }
  }
}


.disabled {
  cursor: auto;
  pointer-events: none;
}

.isOpen {
  padding-bottom: 16px;

  .content {
    margin-top: 16px;
  }
}
