@import "src/styles/v2/variables";

.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;

  .selectContainer {
    margin-bottom: 32px;
  }

  .email {
    position: absolute;
    bottom: -64px;
  }

  @include tablet-breakpoint {
    flex-flow: column;
    justify-content: flex-start;
  }

  form {
    width: 260px;
    display: flex;
    flex-flow: column;

    @include tablet-breakpoint {
      margin-bottom: 64px;
    }
  }

  .group {
    max-width: 320px;
    padding: 16px 24px;
    border-radius: 8px;
    background-color: var(--white);
    box-shadow: 0 2px 16px rgb(14 15 57 / 5%), 0 2px 8px rgb(14 15 57 / 5%);
    margin-bottom: 12px;

    .title {
      margin-bottom: 16px;
      color: var(--grey400);
      text-transform: uppercase;
      font-weight: 400;
      display: flex;
      align-items: center;

      span {
        margin-left: 4px;
        font-weight: 600;
        color: var(--grey600);
      }
    }

    .item {
      padding: 12px 0;
      border-bottom: 1px solid var(--grey200);
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:last-of-type {
        border: none;
      }
    }
  }
}