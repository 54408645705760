@import "src/styles/v2/variables";

.container {
  display: flex;
  flex-flow: column;
  flex: 1;

  .messages {
    display: flex;
    flex-flow: column;
    overflow: auto;
    flex: 1;

    @include mobile-breakpoint {
      overflow: initial;
    }

    @include landscape {
      overflow: initial;
    }

    .noMessages {
      font-size: 16px;
      color: var(--grey400);
      font-weight: 600;
      text-align: center;
    }
  }

  .externalNote {
    display: flex;
    width: 100%;
    padding: 16px 24px;
    background-color: #FFFBF2;
    color: #8D6104;
    border-radius: 8px;
    border: 1px solid #FFEFCD;
    margin-bottom: 16px;

    .externalNoteIcon {
      flex-shrink: 0;
      margin-right: 12px;
    }

    .externalNoteBody {
      text-align: left;
      display: flex;
      flex-flow: column;
      justify-content: center;

      a {
        color: #8D6104;
        font-weight: 600;
        text-decoration: underline;
      }

      .externalNoteTitle {
        margin-bottom: 8px;
        color: #8D6104;
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: .3px;
        align-self: flex-start;
      }

      p {
        font-size: 14px;
        white-space: break-spaces;
      }
    }
  }

  .closeConversation {
    color: #cc6844;
    margin-bottom: 8px;
    text-decoration: underline;
    cursor: pointer;
    align-self: flex-end;
  }

  .message {
    display: block;
    position: relative;
    margin-bottom: 16px;

    textarea {
      width: 100%;
      background-color: var(--grey50);
      border: 1px solid var(--grey50);
      border-radius: 8px;
      margin: 0;
      box-sizing: border-box;
      min-height: 120px;
      resize: vertical;
      max-height: 30vh;
    }

    .input {
      display: block;
      width: calc(100% - 4px);
      margin: 2px auto 0 auto;
      padding: var(--input-padding);
      background-color: var(--input-background);
      border-radius: var(--radius-medium);
      font-size: var(--fontsize-16);
      color: var(--grey800);
      outline: none;
    }

    .input:focus {
      background-color: var(--white);
      border-radius: var(--radius-medium);
      outline: none;
      box-shadow: 0 0 0 2px var(--brand);
    }

    button {
      margin: 16px 0 0 auto;
      display: block;
      height: 48px;

      .spinner {
        transform: scale(0.75);
        margin-left: 4px;
      }
    }
  }
}