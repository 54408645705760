@import "src/styles/v2/variables";

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;
  
  /* Grays/Grey 100 */
  
  background: #F3F4F6;
  border-radius: 8px;
  
  /* Inside auto layout */
  
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  .ratingWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
  }
}

.divider {
  /* Grays/Grey 300 */

  border: 1px solid #D1D5DB;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
.comment {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* or 129% */

  padding: 16px 24px;
  
  
  /* Grays/Grey 500 */
  
  color: #6B7280;
  
  
  /* Inside auto layout */
  
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  
}