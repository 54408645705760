.radio {
  display: flex;
  min-height: 32px;
  align-items: flex-start;

  label {
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.radio {
  margin-bottom: 6px;

  display: flex;
}

.radio [type="radio"]:checked,
.radio [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.radio [type="radio"]:checked + label,
.radio [type="radio"]:not(:checked) + label {
  position: relative;
  padding: 16px;
  padding-left: 44px;
  cursor: pointer;
  line-height: 20px;
  display: flex;
  color: #6B7280;
  // background-color: var(--grey50);
  border: 1px solid #6B7280;
  width: 100%;
  border-radius: 8px;
}

.radio [type="radio"]:checked + label:before,
.radio [type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 16px;
  top: 20px;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}

.radio [type="radio"]:checked + label {
  border: 1px solid var(--brand);
  color: #DE6139;
  background-color: #F7DCD3;
}

.radio [type="radio"]:checked + label:after,
.radio [type="radio"]:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: var(--brand);
  border-color: var(--brand);
  position: absolute;
  top: 24px;
  left: 20px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.radio [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.radio [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.radioGroup {
  display: flex;
  flex-wrap: wrap;
}

.radioGroup .radio {
  margin-right: 8px;
}

.description {
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: #6B7280; //var(--grey500);
  margin-top: 12px;
}

.label {
  position: relative;

  svg {
    margin: auto auto auto 8px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: auto;
  }
}

.iconz {
  padding-top: 1px;
}