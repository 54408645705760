@import "src/styles/v2/variables";

.content {
  display: flex;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  margin: 16px 0;
  background: #FCEBB8;

  .wrapper {
    text-align: left;
    display: flex;
    flex-flow: row;
    justify-content: center;
    font-weight: 200;

    .description {
      @include mobile-breakpoint {
        justify-content: left;
        display: flex;
        flex-flow: column;
        width: 200px;
      }
      
      .totalCredits {
        padding-left: 4px;
        
      }
    }
    

    a {
      color: #9A7938;
      text-decoration: underline;
      padding-left: 4px;
    }

    p {
      color: #9A7938;
    }

    .icon {
      margin-right: 12px;
      margin-top: 1px;
      path {
        stroke: #DE6139;
      }
    }
  }
}

.contentWithIcon {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  gap: 8px;
  background: #E7EFEF;
  border: 1px solid #A1C0BE;
  border-radius: 8px;

  .wrapper {
    display: flex;
    text-align: left;
    flex-flow: row;
    justify-content: center;
    font-weight: 200;

    .headerText {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 150%;
      color: #366964;
    }

    .body {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 120%;
      color: #366964;

      & a {
        color: #366964;
        text-decoration: underline;
      }
    }
    
    .icon {
      margin-right: 12px;
      margin-top: 1px;
      path {
        stroke: #366964;
      }
    }
  }

  & a {
    cursor: pointer;
  }
}
