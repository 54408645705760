@import "src/styles/v2/variables";

.content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 12px;
  gap: 12px;

  @include mobile-breakpoint {
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .wrapper {
    text-align: left;
    display: flex;
    flex-flow: row;
    justify-content: center;
    font-weight: 200;
  }
}

.contentUnrated {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
  padding: 8px 8px;
  border-radius: 8px;
  background: #F3F4F6;

  .wrapper {
    text-align: left;
    display: flex;
    flex-flow: row;
    justify-content: center;
    font-weight: 200;
    background: #F3F4F6;
  }

  @include mobile-breakpoint {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    width: 300px;
    gap: 6px;
  }
}
