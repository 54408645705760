.modal {
  width: fit-content;
  height: 95vh;
  max-width: initial;
  background-color: #f9f9f9;

  .container {
    background-color: #f9f9f9;
    padding: 0 16px 16px 16px;

    .title {
      margin-bottom: 8px;
      font-size: 28px;
      color: var(--grey700);
      letter-spacing: -.03em;
    }

    .description {
      font-size: 16px;
      color: var(--grey500);
      margin-bottom: 32px;
    }
  }
}
