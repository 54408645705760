@import "src/styles/v2/variables";

.content {
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;

  opacity: .5;
  &:hover {
    opacity: 1;
  }
}
.wrapper {
  color: white;
  font-size: 14px;

  @include mobile-breakpoint {
    color: white;
  }

}
.alertIcon {
  opacity: 1;
}

.content:after {
  content: " ";
  position: absolute;
  bottom: -16px;
  right: 0;
  left: 0;
  height: 4px;
  width: 67%;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
  background-color: var(--grey300);
  &:hover {
    background-color: white;
    opacity: 1;
  }
}

.mobileContent {
  @include mobile-breakpoint {
    font-size: 14px;
    text-decoration: none;
    color: var(--grey600);
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
  }
}

.mobileLeftPill {
  color: var(--grey600);
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.creditsPill {
  color: #6B7280;
  display: flex;
  gap: 5px;
  align-content: center;
  align-items: center;
  background: #F3F4F6;
  border-radius: 8px;
  padding: 6px;
  gap: 6px;
}