@import "src/styles/v2/variables";

.container {
  position: absolute;
  width: 100%;
  padding: 16px;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 3000;

  .logo {
    transform: scale(0.8);
    cursor: pointer;

    & > div {
      width: 126px;
      height: 29px;
    }
  }

  .signup,
  .login {
    cursor: pointer;
  }

  span {
    @include text-medium;
    color: $light-300;
    white-space: nowrap;

    &:hover {
      color: $light-100;
    }
  }

  .steps {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .progress {
      position: absolute;
      top: 0;
      height: 4px;
      left: 0;
      background: $brand-400;
    }

    .step {
      width: 33.33%;

      span {
        @include text-headline;
        font-size: 14px;
        line-height: 14px;
        color: $dark-100;
        margin-left: 4px;
      }
    }

    .activeStep {
      span {
        color: $light-300;
      }
    }

    .counter {
      color: $dark-100;
      @include text-headline;
      font-size: 14px;
      line-height: 14px;
    }
  }
}
