@import "../../../../styles/v2/variables";

.overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
}

.content {
  display: block;
  width: 100%;
  height: initial;
  max-height: 100vh;
  max-width: 560px;
  position: relative;
  margin: 0 auto;
  background: #fff;
  text-align: center;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08), 0 4px 18px rgba(0, 0, 0, .08), 0 2px 16px -16px rgba(0, 0, 0, .08);
  overflow: initial;
  -webkit-overflow-scrolling: touch;
  border-radius: 16px;
  outline: none;
  padding: 48px 24px 24px 24px;

  .close {
    background: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 999;
  }

  .body {
    width: 100%;
    height: 100%;
    position: relative;
  }
}

.fullscreen {
  display: block;
  width: 100%;
  height: initial;
  position: relative;
  margin: 0 auto;
  background: #fff;
  text-align: center;
  overflow: initial;
  -webkit-overflow-scrolling: touch;
  outline: none;
  padding: 48px 24px 24px 24px;

  .close {
    background: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 999;
  }

  .body {
    width: 100%;
    height: 100%;
    position: relative;
  }
}
