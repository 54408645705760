@import "src/styles/v2/variables";

.content {
  flex-direction: row;
  align-items: center;
  gap: 12px;
  padding: 7px;

  .wrapper {
    text-align: left;
    display: flex;
    flex-flow: row;
    justify-content: center;
    font-weight: 200;
    button {
      background: none;
    }
  }
}
