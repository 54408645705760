
.musician {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: var(--white);
  box-shadow: 0 2px 16px rgba(14, 15, 57, 0.05), 0 2px 8px rgba(14, 15, 57, 0.05);
  border-radius: 8px;
  margin-bottom: 24px;
  margin-right: 16px;
  margin-left: 16px;
  height: 264px;

  /* Temporary */
  width: calc(928px / 3);
}

.musician__addonLabel {
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 8px;
  border-radius: 24px;
  font-size: 12px;
  background-color: #F9F3E8;
  color: #D4982B;
}

.musicianIntro {
  display: flex;
  align-items: center;
  margin-right: 40px;
  margin-bottom: 16px;
}

.musicianAvatar {
  width: 64px;
  height: 64px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 12px;
  flex-shrink: 0;
}

.sessionMusicianAvatar img {
  max-width: 100%;
}

.musicianInstrument{
  font-size: 14px;
  font-weight: 500;
  color: var(--grey500);
}

.musicianName {
  font-size: 16px;
  color: var(--grey800);
  font-weight: 500;
}

.musicianBio {
  margin-bottom: 24px;
  display: block;
  display: -webkit-box;
  max-width: 100%;
  height: 64px;
  margin: 0 auto 24px 0;
  font-size: 14px;
  line-height: 1;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.musicianBioText {
  color: var(--grey500);
  line-height: 1.5;
}

.musicianActions {
  display: flex;
  width:  100%;
  justify-content: space-between;
  align-self: flex-end;
}

.musicianButton {
  width:  calc( 50% - 6px);
  display: block;
  padding: 12px 0;
  background-color: transparent;
  border-radius: var(--button-radius);
  font-size: var(--fontsize-14);
  color: var(--brand);
  border: 1px solid var(--brand);
  text-align: center;
  cursor: pointer;
  outline: none;
  transition: border-color .15s, color .15s;
}

.musicianSecondaryButton {
  width:  calc( 50% - 6px);
  text-align: center;
  padding: var(--button-padding);
  border-radius: var(--button-radius);
  font-size: var(--fontsize-14);
  color: var(--grey500);
  border: 1px solid var(--grey300);
}

.musicianButton:hover {
  border-color: #E06C47;
  color: #E06C47;
  background-color: #FFFAF9;
}

.musicianSecondaryButton:hover {
  border-color: var(--grey300);
  color: var(--grey600);
}

/* Mobile */

@media (max-width: 768px) {
  .musician {
    width: auto;
  }

  .musicianBio {
    margin-bottom: 8px;
  }

  .musicianActions {
    width: 100%;
    flex-flow: column;
    order: reverse;
  }
  .musicianButton {
    width: 100%;
  }
  .musicianSecondaryButton {
    margin-bottom: 12px;
    width:  100%;
  }
}
