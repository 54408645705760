:root {
    --app-height: 100%;

    /* Grey Colors */
    --white: #fff;
    --grey50: #F9FAFB;
    --grey100: #F3F4F6;
    --grey200: #E5E7EB;
    --grey300: #D1D5DB;
    --grey400: #9CA3AF;
    --grey500: #6B7280;
    --grey600: #4B5563;
    --grey700: #374151;
    --grey800: #1F2937;
    --grey900: #111827;
    --black: #000000;

    /* Brand Colors*/
    --brand: #DE6139;
    --brand100: #F0B9A8;
    --brand200: #EDA892;
    --brand300: #E9967C;
    --brand400: #E58566;
    --brand500: #E27350;

    /* Danger */
    --danger: #c53939;
    /* Success */
    --success: #44837D;

    /* Border Radius */
    --radius-small: 4px;
    --radius-medium: 8px;

    /* Font Sizes */
    --fontsize-13: 13px;
    --fontsize-14: 14px;
    --fontsize-16: 16px;
    --fontsize-18: 18px;
    --fontsize-24: 24px;
    --fontsize-36: 36px;

    /* Spacing */
    --space-8: 8px;
    --space-12: 12px;
    --space-16: 16px;
    --space-24: 24px;
    --space-32: 32px;
    --space-36: 36px;
    --space-40: 40px;
    --space-104: 104px;

    /* Input */
    --input-background: var(--grey50);
    --input-padding: var(--space-16) 0 var(--space-16) var(--space-24);

    /* Button */
    --button-background: var(--brand);
    --button-textColor: var(--white);
    --button-padding: var(--space-12) var(--space-16);
    --button-radius: var(--radius-medium);
    --button-boxShadow: 0px 2px 8px rgba(222, 97, 57, 0.3);

    --button-backgroundFacebook: #3B5998;
    --button-backgroundGoogle: #4285F4;

    --button-boxShadowFacebook: 0px 2px 8px rgba(59, 89, 152, 0.3);
    --button-boxShadowGoogle: 0px 2px 8px rgba(59, 89, 152, 0.3);

    html, body {
        height: -webkit-fill-available;
    }
}
