@import "src/styles/v2/variables";

.modalWrapper {
  padding: 0 !important;
  width: 770px;
  height: fit-content;
  max-width: 779px;

  @include mobile-breakpoint {
    max-width: 100vw;
    width: 90vw;
  }
}

.plans__body {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  height: 100%;

  .leftSide {
    width: 350px;
    font-size: 0;
    overflow: hidden;

    @include mobile-breakpoint {
      display: none;
    }

    img {
      object-fit: cover;
      object-position: center;
      height: 100%;
      width: 100%;
    }
  }

  .rightSide {
    padding: 24px 24px 40px 24px;
    flex: 1;
    display: flex;
    flex-flow: column;
    align-items: flex-start;

    @include mobile-breakpoint {
      padding: 12px 12px 20px 12px;
    }

    .header {
      color: $dark-100;
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 40px;

      @include mobile-breakpoint {
        position: relative;
        top: 12px;
      }
    }

    .plansContainer {
      width: 100%;
      margin-bottom: 36px;

      .plan {
        width: 100%;
        height: fit-content;
        padding: 12px 16px;
        background: #F2F2F2;
        border-radius: 8px;
        margin-bottom: 16px;
        cursor: pointer;
        border: 1px solid #F2F2F2;

        @include mobile-breakpoint {
          padding: 6px;
        }

        &:hover {
          opacity: 0.9;
        }

        &:last-of-type {
          margin: 0;
        }

        .row {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .mediumRow {
          margin: 4px 0 8px 0;
          padding-bottom: 8px;
          border-bottom: 1px solid #DDDDDD;
        }

        .bigText {
          font-size: 16px;
          line-height: 19.36px;
          color: #DD6039;
          font-weight: 700;

          @include mobile-breakpoint {
            font-size: 14px;
          }
        }

        .bigTextGray {
          color: $dark-100;
        }

        .mediumText {
          font-size: 12px;
          color: #7A7A7A;
          font-weight: 500;

          @include mobile-breakpoint {
            font-size: 11px;
          }

        }

        .mediumTextLight {
          font-weight: 400;
        }

        .smallText {
          font-size: 10px;
          line-height: 12px;
          color: #938C8C;
          font-weight: 600;
          display: flex;
          align-items: center;

          .icon {
            margin-left: 2px;
            transform: scale(0.85);
          }
        }
      }

      .selectedPlan {
        border: 1px solid #DE6139;
        background: rgba(222, 97, 57, 0.1);
      }
    }

    .bullets {
      text-align: left;
      list-style-type: disc;
      list-style-position: inside;

      li {
        color: var(--grey700);
        font-size: 12px;
        line-height: 24px;
      }
    }

    .plans__planButton {
      width: 100%;
      margin-top: 48px;
      padding: var(--button-padding);
      background-color: var(--button-background);
      border-radius: var(--button-radius);
      font-size: var(--fontsize-15);
      box-shadow: var(--button-boxShadow);
      color: var(--button-textColor);
      text-align: center;
      cursor: pointer;
      outline: none;
      font-family: "Inter", sans-serif;
      font-weight: 400;

      &:hover {
        opacity: 0.9;
      }
    }

    .plans__planButton.isCurrent {
      background-color: var(--grey200);
      color: var(--grey600);
      box-shadow: none;
      cursor: default;
    }
  }
}

.switchPlanExplanation {
  font-weight: 300;
  font-style: italic;
  font-size: 13px;
  padding-top: 20px;
}

.paymentMethodModal {
  width: 80%;
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: auto;

  h1 {
    position: relative;
    top: -8px;
    color: var(--grey400);
    margin-bottom: 16px;
    font-size: 18px;
  }

  .methods {
    display: flex;
    justify-content: center;

    .method {
      width: 180px;
      height: 144px;
      background: var(--grey200);
      border-radius: 8px;
      border: 1px solid var(--grey200);
      box-shadow: 0 2px 16px rgb(14 15 57 / 5%), 0 2px 8px rgb(14 15 57 / 5%);
      margin: 0 16px;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover:not(.methodSelected) {
        background-color: var(--grey100);
      }

      p {
        font-family: "Inter", sans-serif;
        margin-bottom: 22px;
        font-size: 20px;
      }
    }

    .methodSelected {
      border: 1px solid var(--brand);
      background-color: #FFF1ED;
      cursor: auto;
    }
  }

  .joinButton {
    width: calc(100% - 16px);
    margin-top: 24px;
    padding: var(--button-padding);
    background-color: var(--button-background);
    border-radius: var(--button-radius);
    font-size: var(--fontsize-16);
    box-shadow: var(--button-boxShadow);
    color: var(--button-textColor);
    text-align: center;
    cursor: pointer;
    outline: none;
    font-family: "Inter", sans-serif;
  }
}

.rolloverWarningModal {
  width: 80%;
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: auto;

  h1 {
    position: relative;
    top: -8px;
    color: var(--grey400);
    margin-bottom: 16px;
    font-size: 18px;
  }

  .rolloverInfoContainer {
    text-align: left;
    margin-bottom: 8px;
    border: none;
  }

  .joinButton {
    width: calc(100% - 16px);
    margin-top: 8px;
    padding: var(--button-padding);
    background-color: var(--button-background);
    border-radius: var(--button-radius);
    font-size: var(--fontsize-16);
    box-shadow: var(--button-boxShadow);
    color: var(--button-textColor);
    text-align: center;
    cursor: pointer;
    outline: none;
    font-family: "Inter", sans-serif;
  }
}

// downgrade modal rollover content

.rolloverInfoContainer {
  display: flex;
  flex-flow: column;
  padding: 16px 0;
  margin-bottom: 16px;
  border-top: 1px solid var(--grey200);

  span {
    color: var(--grey600);
    font-style: italic;
  }

  span:first-of-type {
    margin-bottom: 8px;
  }
}

.confirmButton {
  width: 100%;
  display: block;
  text-align: center;
  cursor: pointer;
  margin-bottom: 16px;
  padding: var(--button-padding);
  background-color: var(--button-background);
  border-radius: var(--button-radius);
  font-size: var(--fontsize-16);
  box-shadow: var(--button-boxShadow);
  color: var(--button-textColor);
  outline: none;
  font-family: "Inter", sans-serif;
}

.vat {
  margin-left: auto;
  
  span {    
    color: var(--grey500);
  }
}
