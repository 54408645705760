/*
 * Error Message
 */


.InputError {
  display: flex;
  margin-top: 8px;
  color: var(--danger);
}

.InputError__icon {
  position: relative;
  top: -1px;
  margin-right: 8px;
  display: flex;
  align-items: center;
}