@import "src/styles/v2";

.session {
  display: flex;
  align-items: center;
  padding: 16px 24px;
  margin-bottom: 12px;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0px 2px 16px rgba(14, 15, 57, 0.05), 0px 2px 8px rgba(14, 15, 57, 0.05);
  transition: transform .15s;
}

.session:hover {
  transform: scale(1.03);
  cursor: pointer;
}


.sessionMusician {
  display: flex;
  align-items: center;
  margin-right: 40px;
  min-width: 30%; /* Make sure all items take the same width for table-like alignment */
}

.sessionMusicianAvatar {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 12px;
}

.sessionMusicianAvatar img {
  max-width: 100%;
}

.sessionMusicianInstrument {
  font-size: 16px;
  font-weight: 500;
  color: var(--grey800);
}

.sessionMusicianName {
  font-size: 14px;
  color: var(--grey500);
  font-weight: 500;
}

.sessionTime {
  margin-right: 80px;
}

.sessionTimeDate {
  font-size: 14px;
  color: var(--grey800);
}

.sessionTimeNumber, .trackOptions{
  font-size: 14px;
  color: var(--grey500);
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.sessionTrack {
  max-width: 23%;

  .sessionTrackName {
    font-size: 16px;
    color: var(--grey600);
    font-weight: 500;
    word-break: break-word;
  }

  @media (max-width: 768px) {
    max-width: 100%;

    .sessionTrackName {
      margin-bottom: 4px;
    }

    .trackOptions {
      margin-bottom: 8px;
    }
  }
}

/**
 * Session States
 */ 

.sessionStatus {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  font-size: 14px;
  color: var(--grey700);
  padding: 8px 12px;
  border-radius: 24px;
  background-color: var(--brand);
}

.sessionStatus.isFilesRequired {
  background-color: #FCF3F0;
  color: #DE6139;
}

.sessionStatus.isPending {
  background-color: #FFF7EA;
  color: #D3931F;
}

.sessionStatus.isUpdatesRequired {
  background-color: #FFF7EA;
  color: #D3931F;
}

.sessionStatus.isReadyToGo {
  background-color: #F0F6F6;
  color: #44837D;
}

.sessionStatus.isCanceled {
  background-color: #F3F4F6;
  color: #6B7280;
}

.sessionStatus.isPreparingFiles {
  background-color: #F3F4F6;
  color: #6B7280;
}

.sessionStatus.isReadyToDownload {
  background-color: #F0F6F6;
  color: #44837D;
  
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  
  width: 44px;
  height: 32px;
  background: #E7EFEF;
  border-radius: 8px;
  padding-right: 5px;

  &:hover :not(.partnerView) {
    background: #E7EFEF;
    // background: #E8E9EB;
  }
  &.partnerView {
    width: 15%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 14px;
    height: 40px;
    gap: 10px;
    @include mobile-breakpoint {
      width: 100%;
    }
  }
}
.sessionStatus.restrictedDownload {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 14px;
  gap: 10px;
  cursor: pointer;

  background: #F3F4F6;
  border-radius: 8px;

  path {
    stroke: #9CA3AF;
  }
}

.sessionStatus.withRating {
  background-color: #F0F6F6;
  color: #44837D;
  
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  
  width: 44px;
  height: 32px;
  background: #E7EFEF;
  border-radius: 8px;
  padding-right: 5px;

  &:hover {
    background: #E8E9EB;
  }
}

/* Canceled Session */
.session.isCancelled {
  opacity: .7;
}

.session.isCancelled .sessionTimeDate,
.session.isCancelled .sessionTimeNumber {
  text-decoration: line-through;
}

.sessionActionsCancelled {
  color: var(--grey400);
}

.actionIcons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  display: flex;
  gap: 10px;
  padding-right: 0px;
  @include mobile-breakpoint {
    width: 100%;
  }
}

.actionButton {
  color: white;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 14px;
  gap: 10px;

  /* Brand/Brand 700 */

  background: #DE6139;
  border-radius: 8px;

  &:hover {
    background: #E58566;
  }
  @include mobile-breakpoint {
    width: 100%;
  }
}

.fileUploadIcon {
  padding-top: 6px;
}

/* Mobile */

@media (max-width: 768px) {
  .session {
    flex-flow: column;
    align-items: flex-start; 
  }

  .sessionMusician {
    margin-bottom: 16px;
  }

  .sessionTime {
    margin-bottom: 16px;
  }

  .sessionTrackName {
    margin-bottom: 16px;
  }

  .sessionStatus {
    margin-left: 0;
  }
}

.mobileActionIcons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  display: flex;
  width: 300px;
  gap: 10px;
}

.mobileSessionStatus.isReadyToDownload {
  color: #44837D;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 14px;
  gap: 10px;
  /* Success/Success 50 */
  
  background: #E7EFEF;
  border-radius: 8px;
  
  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  &:hover {
    background: #E8E9EB;
  }
}

.mobileSessionStatus.restrictedDownload {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 14px;
  gap: 10px;
  
  /* Success/Success 50 */
  
  background: #F3F4F6;
  border-radius: 8px;
  color: #9CA3AF;
  
  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  path {
    stroke: #9CA3AF;
  }
}

.mobileSessionStatus.withRating {
  display: flex;
  flex-direction: row;
  color: #44837D;
  justify-content: center;
  align-items: flex-start;
  padding: 16px 14px;
  gap: 10px;
  
  /* Success/Success 50 */
  
  background: #E7EFEF;
  border-radius: 8px;
  
  /* Inside auto layout */
  
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  &:hover {
    background: #E8E9EB;
  }
}