@import "src/styles/v2/variables";

$primary_box_shadow: 0 4px 24px rgba(224, 178, 59, 0.2), 0 2px 24px rgba(0, 0, 0, 0.05), inset 0 -1px 0px rgba(0, 0, 0, 0.5);
$secondary_box_shadow: 0 8px 8px -8px rgba(19, 19, 19, 0.15), 0 4px 4px rgba(19, 19, 19, 0.15), 0 2px 2px rgba(19, 19, 19, 0.15), inset 0 0px 2px rgba(180, 180, 177, 0.5);

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  width: fit-content;

  .contentContainer {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-content: center;
    padding: 0 9%;
  }

  &:disabled {
    background: rgba(227, 186, 79, 0.5);
    cursor: auto;

    span {
      color: #372800;
    }

    &:hover {
      background: rgba(227, 186, 79, 0.5);
    }
  }

  .spinner {
    position: absolute;
    left: 13px;
    top: 0;
    bottom: 0;
    margin: auto 0;
  }
}

.clickable {
  cursor: pointer;
}

.default {
  background: $brand-400;
  box-shadow: $primary-box_shadow;

  .buttonText {
    color: $brand-700;
  }

  &:hover {
    background: $brand-300;
  }
}

.outline {
  border: 0.1px solid $brand-400;
  background: transparent;

  .buttonText {
    color: $brand-400;
  }

  &:hover {
    border-color: $brand-300;

    .buttonText {
      color: $brand-300;
    }
  }
}

.secondary {
  box-shadow: $secondary_box_shadow;
  background: $dark-500;

  .buttonText {
    color: $light-600;
  }

  &:hover {
    background: $dark-300;
  }
}

.large {
  padding: 16px 32px;

  .buttonText {
    font-family: $maison-neue;
    font-weight: $font-weight-bold;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 1px;
    padding-top: 3px;
  }
}

.medium {
  padding: 16px 24px;

  .buttonText {
    font-family: $maison-neue;
    font-weight: $font-weight-bold;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.8px;
  }
}

.small {
  padding: 16px 24px;

  .buttonText {
    font-family: $maison-neue;
    font-weight: $font-weight-bold;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.6px;
    padding-top: 3px;
  }
}

.icon {
  width: 48px;
  height: 48px;
}

.arrowButton {
  padding: 0;
}
