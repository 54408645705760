.label {
  display: block;
  font-size: 16px;
  color: var(--grey700);
}

.selectIcon {
  position: absolute;
  content: "&";
  top: 0;
  bottom: 0;
  margin: auto;
  width: 24px;
  height: 24px;
  right: 16px;
  display: flex;
  align-items: center;
}