@import 'styles/v2/variables';

.bookACallContainer {
  padding: 0 32px;
  margin-bottom: 52px;

  .title {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    margin: auto;

    @include mobile-breakpoint {
      width: 80%;
    }
  }

  .titleDescription {
    margin: 0 auto 32px auto;
    color: var(--grey600);
    font-size: 16px;
  }

  .messageContainer {
    display: flex;
    height: fit-content;
    align-items: flex-end;

    .avatar {
      display: flex;

      img {
        border-radius: 50%;
      }
    }

    .message {
      margin-left: 16px;
      padding: 16px;
      background: var(--grey200);
      flex: 1;
      text-align: start;
      font-size: var(--fontsize-16);
      border-radius: 16px;

      p {
        margin-bottom: 16px;
      }
    }
  }

  .personTitle {
    text-align: end;
    margin-top: 16px;

    a {
      text-decoration: underline;
    }

    @include mobile-breakpoint {
      display: flex;
      flex-flow: column;

      .pipe {
        display: none;
      }
    }
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 0 32px 32px 32px;
}

.back {
  font-size: var(--fontsize-16);
  color: var(--grey600);
  cursor: pointer;
  background: transparent;
}

.button {
  padding: var(--button-padding);
  background-color: var(--button-background);
  border-radius: var(--button-radius);
  font-size: var(--fontsize-14);
  box-shadow: var(--button-boxShadow);
  color: var(--button-textColor);
  text-align: center;
  cursor: pointer;
  outline: none;
  width: 112px;
  height: 49px;
}
