@import "src/styles/v2/variables";

.icon {
  align-self: center;
  margin-bottom: 24px;
}

.title {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: var(--grey800);
  text-align: center;
  margin-bottom: 24px;
}

.subtitle {
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: var(--grey500);
  margin-bottom: 24px;
}