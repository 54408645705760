@import "src/styles/v2/variables";

.container {
  @include content-wrapper;
  margin-top: 40px;

  .pricingBox {
    padding-bottom: 32px;
    margin: auto;
    max-width: 400px;
    height: fit-content;
    background: $dark-600;
    display: flex;
    flex-flow: column;
    border-radius: 6px;
    position: relative;
    box-shadow: 0 16px 16px -8px rgba(19, 19, 19, 0.15), 0 4px 4px rgba(19, 19, 19, 0.15), 0 2px 2px rgba(19, 19, 19, 0.15), inset 0 0 2px rgba(235, 234, 231, 0.5);

    .tabs {
      width: 100%;
      display: flex;
      background: $dark-300;
      box-shadow: 0 16px 16px rgba(19, 19, 19, 0.15), 0 4px 4px rgba(19, 19, 19, 0.15), 0 2px 2px rgba(19, 19, 19, 0.15), inset 0 0 2px rgba(235, 234, 231, 0.5);
      border-radius: 6px;
      overflow: hidden;
      position: relative;
      z-index: 1;
      margin: 0 auto 8px auto;

      &:after {
        background: $dark-300;
        position: absolute;
        content: '';
        height: calc(100% - 4px);
        width: calc(100% - 4px);
        top: 2px;
        left: 2px;
        pointer-events: none;
      }

      .tab {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        height: 54px;
        position: relative;
        z-index: 1;

        span {
          @include text-small;
          line-height: 14px;
          letter-spacing: 0.8px;
          color: $light-700;
        }
      }

      .tabActive {
        cursor: default;
        border-radius: 6px;
        box-shadow: inset 0 0 2px rgba(235, 234, 231, 0.5);
        background: $dark-200;

        span {
          color: $light-300;
        }
      }
    }

    .discountContainer {
      width: calc(100% - 56px);
      margin: 24px auto -12px auto;
      background: rgba(224, 178, 59, 0.02);
      border: 1px solid $brand-400;
      border-radius: 8px;
      display: flex;
      align-items: center;
      padding: 12px 16px;

      span {
        color: $brand-400;
        @include text-small;
        line-height: 16px;
        margin-left: 8px;
      }
    }

    .priceContainer {
      display: flex;
      flex-flow: column;
      align-items: center;
      margin-top: 32px;

      .priceValueContainer {
        position: relative;
        height: fit-content;
        padding-top: 2px;
        margin-bottom: 8px;

        .dollar {
          color: $light-700;
          @include text-headline;
          font-size: 18px;
          line-height: 18px;
          position: absolute;
          top: 0;
          left: -16px;
        }

        .price {
          @include text-headline;
          position: relative;
          color: $white;
          font-size: 48px;
          line-height: 48px;
          text-shadow: 0 8px 32px rgba(19, 19, 19, 0.1), 0 4px 8px rgba(19, 19, 19, 0.1);
        }

        .oldPriceContainer {
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto 0;
          left: calc(100% + 20px);
          z-index: 1;
          height: fit-content;

          &:after {
            position: absolute;
            content: '';
            width: calc(100% * 1.21);
            height: 2px;
            bottom: calc(10px - 35%);
            left: 2%;
            background: $light-700;
            box-shadow: 0 1px 0 #202020;
            transform: rotate(-32deg);
            transform-origin: bottom left;
            padding-left: 2px;
          }

          .dollar {
            color: $light-700;
            @include text-headline;
            font-size: 12px;
            line-height: 12px;
            position: absolute;
            top: 0;
            left: -10px;
          }

          .oldPrice {
            @include text-headline;
            position: relative;
            color: $light-700;
            font-size: 24px;
            line-height: 24px;
            text-shadow: 0 8px 32px rgba(19, 19, 19, 0.1), 0 4px 8px rgba(19, 19, 19, 0.1);
          }
        }
      }

      .planLabel {
        color: $light-700;
        @include text-small;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.7px;
      }
    }

    .sliderContainer {
      width: 100%;
      height: fit-content;
      padding: 0 15px;
      margin-top: 28px;
      margin-bottom: 36px;

      .slider {
        height: fit-content;
        margin: auto;
        width: calc(100% - 24px);

        .mark {
          background: $dark-200;
          width: 2px;
          height: 8px;
          margin-top: 4px;
          margin-left: 24px;
        }

        .track {
          background: $dark-300;
          height: 16px;
          border-radius: 17px;
        }

        .thumb {
          top: -9px;
          outline: none;
          width: 48px;
          height: 32px;
          background: $brand-400;
          box-shadow: 0 4px 24px rgba(224, 178, 59, 0.2), 0 2px 24px rgba(0, 0, 0, 0.05), inset 0 -1px 0 rgba(0, 0, 0, 0.5);
          border-radius: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          &:hover {
            box-shadow: 0px 0px 0px 6px rgba(241, 220, 167, 0.05), 0px 4px 24px rgba(224, 178, 59, 0.5), 0px 2px 24px rgba(0, 0, 0, 0.05), inset 0px -1px 0px rgba(0, 0, 0, 0.5);
          }

          .triangleLeft {
            width: 0;
            height: 0;
            border-top: 6px solid transparent;
            border-right: 6px solid $brand-600;
            border-bottom: 6px solid transparent;
            margin-right: 3px;
          }

          .triangleRight {
            width: 0;
            height: 0;
            border-top: 6px solid transparent;
            border-left: 6px solid $brand-600;
            border-bottom: 6px solid transparent;
            margin-left: 3px;
          }
        }
      }
    }

    .bullets {
      display: flex;
      flex-flow: column;
      width: 100%;
      padding: 0 16px;

      .bullet {
        width: 100%;
        padding: 12px 8px 12px 16px;
        border-radius: 8px;
        display: flex;
        align-items: center;

        .icon {
          position: relative;
          margin: auto 0;
          top: 4px;
        }

        &:hover {
          background: rgba(19, 19, 19, 0.5);
          box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
        }

        span {
          @include text-medium;
          line-height: 19.41px;
          color: $light-300;
          margin-right: 8px;
          white-space: nowrap;
        }
      }
    }
  }
}

.tooltipContent {
  h3 {
    color: $dark-100;
    @include text-headline;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 6px;
  }

  p {
    @include text-small;
    color: $light-400;
  }
}
