@import "src/styles/v2/variables";

/*
 * Google Login Button
 */

.button {
  position: relative;
  width: 100%;
  padding: var(--button-padding);
  background: $light-100;
  box-shadow: 0 4px 24px rgba(24, 119, 242, 0.2), 0 2px 24px rgba(0, 0, 0, 0.05), inset 0 -1px 0px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  @include text-headline;
  letter-spacing: 0.8px;
  font-weight: $font-weight-bold;
  font-size: var(--fontsize-16);
  color: $dark-300;
  text-transform: uppercase;
  margin-bottom: 16px;
  letter-spacing: .05;;
  text-align: center;
  cursor: pointer;
  outline: none;
}

.buttonIcon {
  display: flex;
  align-items: center;
  position: absolute;
  height: fit-content;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 16px;
}