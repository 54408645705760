@import "src/styles/v2/variables";

.modal.modal {
  width: 70vw;
  max-width: 803px;
  height: fit-content;
  display: flex;
  flex-flow: column;
  padding: 36px 0 0 0;
  position: relative;

  @include mobile-breakpoint {
    width: 90vw;
  }

  .counter {
    position: absolute;
    top: -12px;
    left: 24px;
    color: var(--grey500);
    font-size: 14px;
    line-height: 21px;
  }

  .container {
    display: flex;
    flex-flow: column;
    flex: 1;

    .content {
      padding-bottom: 36px;
    }

    .icon {
      align-self: center;
    }

    h3 {
      color: var(--grey800);
      font-weight: 700;
      font-size: 14px;
      margin-top: 32px;
      padding: 0 24px 0 56px;
      text-align: left;
    }

    .reasons {
      margin-top: 24px;
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      padding: 0 24px;

      .reason {
        color: var(--grey800);
        font-size: 14px;
      }
    }

    textarea {
      min-height: 150px;
      width: 100%;
      margin: 24px auto 0 auto;
      resize: vertical;
      padding: var(--input-padding);
      background-color: var(--input-background);
      border-radius: var(--radius-medium);
      font-size: var(--fontsize-14);
      color: var(--grey800);
      outline: none;
    }

    textarea:focus {
      background-color: var(--white);
      border-radius: var(--radius-medium);
      outline: none;
      border-color: var(--brand);
    }

    .body {
      padding: 0 24px;

      .checkbox {
        margin-top: 12px;

        .checkboxLabel {
          margin-left: 12px !important;
          font-size: 14px;
        }
      }
    }

    .finalStepBody {
      display: flex;
      flex-flow: column;
      align-items: center;
      padding: 0 78px 36px 78px;

      h3 {
        text-align: center;
        margin-bottom: 24px;
        align-self: center;
      }

      p {
        font-size: 14px;
      }

      .finalStepContent {
        text-align: center;
        font-size: 14px;

        a {
          color: initial;
        }
      }
    }
  }

  .footerButton {
    background-color: var(--brand);
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: var(--brand500);
    }

    span {
      color: var(--white);
      font-size: 14px;
    }
  }

  .footerInactive {
    cursor: auto;

    &:hover {
      background-color: var(--brand);
    }

    span {
      color: var(--grey200);
      opacity: 0.55;
    }
  }
}