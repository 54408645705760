.radio {
  display: flex;
  min-height: 32px;
  align-items: flex-start;

  label {
    text-align: left;

    span {
      margin-left: 16px;
      padding-left: 8px;
      display: block;
    }
  }
}

.radio [type="radio"]:checked,
.radio [type="radio"]:not(:checked) {
  display: none;
}

.radio [type="radio"]:checked + label,
.radio [type="radio"]:not(:checked) + label {
  position: relative;
  padding: 8px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
  width: 100%;
  border-radius: 8px;
}

.radio [type="radio"]:checked + label:before,
.radio [type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
}

.radio [type="radio"]:checked + label:after,
.radio [type="radio"]:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: var(--brand);
  border-color: var(--brand);
  position: absolute;
  left: 4px;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.radio [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.radio [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}