@import "src/styles/v2/variables";

.modal.modal {
  width: 70vw;
  max-width: 456px;
  height: fit-content;
  display: flex;
  flex-flow: column;
  padding: 24px;
  position: relative;

  @include mobile-breakpoint {
    width: 90vw;
  }

  .title {
    text-align: left;
    color: $dark-100;
    border-bottom: 1px solid #DDDDDD;
    padding-bottom: 24px;
    font-weight: 700;
    font-size: 16px;
  }

  .counterContainer {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .label {
      font-size: 16px;
      color: $dark-100;
      font-weight: 700;
    }

    .counter {
      width: 88px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .amount {
        color: $dark-100;
        font-weight: 500;
        font-size: 16px;
      }

      .counterButton {
        width: 20px;
        height: 20px;
        background: #F2F2F2;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        cursor: pointer;

        &:hover {
          opacity: 0.9;
        }

        &:disabled {
          cursor: not-allowed;
          opacity: 0.4;
          background: #DDDDDD;
        }
      }
    }
  }

  .paymentOptions {
    display: flex;
    align-items: center;
    margin-top: 24px;

    @include mobile-breakpoint {
      flex-wrap: wrap
    }

    .radioOption {
      width: 100%;
    }
  }

  .payButton {
    width: 100%;
    margin-top: 24px;

    .totalPrice {
      margin: auto;
      margin-top: 6px;
      margin-bottom: 6px;
      font-size: 15px;
      font-weight: 400;

      .crossedOut {
        text-decoration: line-through;
        opacity: 0.55;
      }
    }
  }
}

.countryPickerLabel {
  margin-top: 24px;
  display: flex;

  .label {
    font-size: 16px;
    color: #4B5563;
    font-weight: 00;
  }
}

.modalButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.cancelButton {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  
  text-align: center;
  
  /* Grey Colors/grey500 */
  
  color: #6B7280;
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  grid-gap: 4px;
  gap: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  align-content: center;
  justify-content: center;

  @include mobile-breakpoint {
    font-size: 12px;
  }

  a {
    margin-left: 5px;
    cursor: pointer;
  }
  .secureCheckout{
    display: inline-flex;
    align-items: center;
    color: #6B7280;
    margin-top: 24px;

    .lockIcon {
      margin-right: 6px;
    }
  }
}

