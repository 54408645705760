@import "src/styles/v2/variables";

.title {
  color: var(--grey600);
  font-size: 16px;
  line-height: 17px;
  text-align: left;
  margin-bottom: 8px;
}

.container {
  background-color: $dark-500 !important;
  box-shadow: 0 16px 16px rgba(19, 19, 19, 0.15), 0 4px 4px rgba(19, 19, 19, 0.15), 0 2px 2px rgba(19, 19, 19, 0.15), inset 0 0 1px $dark-100;
  border-radius: 8px;
  padding: 16px 24px;
  display: flex;
  flex-flow: column;
  margin-bottom: 8px;
  transition: background-color .15s;

  .header {
    @include text-big;
    color: $light-400;
    font-size: 16px;
    text-align: left;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    span {
      width: calc(100% - 24px);
    }

    svg {
      path {
        stroke: $light-500;
      }
    }

    .closeIcon {
      transform: rotate(45deg);
    }
  }

  .content {
    @include text-medium;
    line-height: 18px;
    color: $light-500;
    text-align: left;
    max-height: 0;
    overflow: hidden;
    transition: 0.2s ease-out;
    will-change: max-height;
    padding-right: 24px;
    overflow-x: auto;
  }

  &:hover {
    .header {
      opacity: 0.8;
    }
  }
}


.disabled {
  cursor: auto;
  pointer-events: none;
}

.isOpen {
  padding-bottom: 16px;

  .content {
    margin-top: 16px;
  }
}
