@import "src/styles/v2/variables";

.form {
  margin-bottom: 36px;
}

.label {
  color: var(--grey600);
  font-size: 16px;
  line-height: 17px;
  text-align: left;

  .inputContainer {
    display: flex;
    align-items: center;
    margin-top: 8px;

    @include mobile-breakpoint {
      flex-direction: column;
    }

    .input {
      display: block;
      width: 100%;
      font-weight: 400;
      padding: var(--input-padding);
      background-color: var(--input-background);
      border-radius: var(--radius-medium);
      font-size: var(--fontsize-16);
      color: var(--grey800);
      outline: none;
      height: 51px;

      &:focus {
        background-color: var(--white);
        border-radius: var(--radius-medium);
        outline: none;
        box-shadow: 0 0 0 2px var(--brand);
      }
    }

    .button {
      width: 117px;
      height: 51px;
      font-size: 15px;
      font-weight: 500;
      line-height: 18px;
      margin-left: 12px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include mobile-breakpoint {
        margin-top: 8px;
        width: 100%;
        margin-left: 0;
      }
    }
  }
}
