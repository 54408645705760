@import "src/styles/v2/variables";

.modal.modal {
  width: 420px;
  max-width: 420px;
  height: fit-content;
  display: flex;
  flex-flow: column;
  padding: 24px;
  position: relative;


  @include mobile-breakpoint {
    width: 90vw;
  }

  h1 {
    color: #374151;
    font-weight: 700;
    margin-bottom: 16px;

    @include mobile-breakpoint {
      padding-top: 25px;
    }
  }

  .stars {
    padding-bottom: 16px;
  } 

  .inputComment {
    border-radius: 8px;
    padding-top: 13px;
  }

  .submittedIcon {
    padding-top: 25px;
  }

  .thankYouMessage {
    padding-top: 25px;
  }

  .buttonWrapper {
    padding-top: 13px;
    padding-bottom: 12px;
  }

  .button {
    width: 100%;
  }
  
  .buttonDisabled {
    width: 100%;
  }
}