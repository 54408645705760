@import "src/styles/v2/variables";

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column;

  .header {
    display: flex;
    align-items: center;
    padding: 24px 32px;
    background-color: var(--grey100);

    @include mobile-breakpoint {
      padding: 24px;
    }

    .headerTitle {
      color: var(--grey800);
    }

    .headerTitleSessionID {
      display: inline-block;
      color: var(--grey400);
      font-size: 12px;
      margin-left: 4px;
      cursor: pointer;

      &:hover {
        color: var(--grey300);
      }
    }

    .dropdownContainer {
      position: relative;
      margin-left: auto;

      .dotsButton {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background-color: var(--white);
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.03);
        cursor: pointer;
        outline: 0;
      }

      .dropdownMenu {
        position: absolute;
        top: 48px;
        right: 0;
        background: var(--white);
        border-radius: 8px;
        overflow: hidden;
        min-width: 220px;
        box-shadow: 0 0 8px rgba(14, 15, 57, 0.1), 0 0 4px rgba(14, 15, 57, 0.1);
        opacity: 0;
        visibility: hidden;
        transform: translateY(-20px);
        transition: opacity 0.15s ease, transform 0.15s ease, visibility 0.15s;
        z-index: 1000;

        a {
          white-space: nowrap;
        }

        ul {
          list-style: none;
          padding: 0;
          margin: 0;
        }

        li {
          border-bottom: 1px solid var(--grey200);

          &:last-child {
            border-bottom: none;
          }
        }

        li a {
          display: flex;
          align-items: center;
          font-size: 14px;
          text-decoration: none;
          color: var(--grey600);
          padding: 15px 20px;
          cursor: pointer;

          &:hover {
            background-color: var(--grey100);
          }
        }

        .menuItemIcon {
          margin-right: 8px;
          display: block;
          font-size: 0;
        }
      }

      .dropdownMenuOpened {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }
    }

    .closeIcon {
      margin-left: 8px;
      display: none;

      @include tablet-breakpoint {
        display: block;
      }
    }

    .sessionStatus {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 8px;
      font-size: 14px;
      color: var(--grey700);
      padding: 8px 12px;
      border-radius: 24px;
      background-color: var(--brand);
    }

    .sessionStatus.isFilesRequired {
      background-color: #FCF3F0;
      color: #DE6139;
    }

    .sessionStatus.isPending {
      background-color: #FFF7EA;
      color: #D3931F;
    }

    .sessionStatus.isUpdatesRequired {
      background-color: #FFF7EA;
      color: #D3931F;
    }

    .sessionStatus.isReadyToGo {
      background-color: #F0F6F6;
      color: #44837D;
    }

    .sessionStatus.isCanceled {
      background-color: #F3F4F6;
      color: #6B7280;
    }

    .sessionStatus.isPreparingFiles {
      background-color: #F3F4F6;
      color: #6B7280;
    }

    .sessionStatus.isReadyToDownload {
      background-color: #F0F6F6;
      color: #44837D;
    }

    .headerUser {
      display: flex;
      align-items: center;

      .headerUserName {
        color: #414148;
        font-weight: 500;
      }

      .headerUserAvatar {
        width: 48px;
        height: 48px;
        margin-left: 12px;
        border-radius: 100%;
        overflow: hidden;

        .headerUserAvatarImg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .partnersHeader {
    justify-content: space-between;
  }

  .body {
    flex: 1;
    display: flex;
    flex-flow: column;
    overflow: hidden;

    @include mobile-breakpoint {
      overflow: auto;
    }

    @include landscape {
      overflow: auto;
    }


    .tabs {
      padding: 0 40px;
      display: flex;
      margin-top: 4px;
      margin-bottom: 16px;

      @include mobile-breakpoint {
        padding: 0 16px
      }

      .tabsItem {
        width: 25%;
        padding: 0;
        border-bottom: 2px solid var(--grey100);
        color: var(--grey400);
        font-size: 14px;
        cursor: pointer;
        text-align: left;
        display: flex;
        align-items: center;

        a {
          display: block;
          padding: 12px 0;
          color: inherit;
        }
      }

      .isActive {
        color: var(--brand);
        border-color: var(--brand);
        cursor: auto;
      }
    }
  }

  .bodyContent {
    display: flex;
    flex: 1;
    padding: 0 32px 32px 40px;
    overflow: hidden;

    @include mobile-breakpoint {
      overflow: auto;
      padding: 0 16px 32px 16px;
    }

    @include landscape {
      overflow: auto;
    }


    .sessionDetails {
      margin-top: 8px;
      width: 100%;
      display: flex;
      flex: 1;
      flex-flow: column;
      overflow: auto;

      .musician {
        display: flex;
        align-items: center;
        margin-right: 40px;

        .musicianAvatar {
          width: 56px;
          height: 56px;
          border-radius: 100%;
          overflow: hidden;
          margin-right: 12px;
        }

        img {
          max-width: 100%;
        }

        .musicianMetaTitle {
          font-size: 16px;
          color: var(--grey500);
          font-weight: 500;
        }
      }

      .items {
        margin-top: 28px;
        display: flex;
        flex-flow: column;
        overflow: auto;

        @include mobile-breakpoint {
          overflow: initial;
        }

        .itemRow {
          display: flex;
          margin-bottom: 28px;
          justify-content: space-between;

          @include mobile-breakpoint {
            flex-flow: column;
            margin: 0;
          }

          .item {
            width: calc(50% - 8px);
            display: flex;
            justify-content: flex-start;

            @include mobile-breakpoint {
              margin-bottom: 32px;
            }

            .itemLabelValueContainer {
              flex: 1;
              display: flex;
              flex-flow: column;
              overflow: hidden;

              a {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            h3 {
              font-size: 16px;
              color: var(--grey500);
              font-weight: 400;
            }

            .icon {
              margin-right: 16px;
            }

            .referenceTrack {
              word-break: break-word;
            }
          }

          .fullWidthItem {
            width: 100%;
          }
        }

        .filesButtons {
          display: flex;
          flex: 1;

          button {
            width: 50%;

            &:last-of-type {
              margin-left: 8px;
            }
          }
        }

        .itemButton {
          background-color: #FFF1ED;
          color: var(--brand);
          border-radius: 8px;
          padding: 8px 16px;
          display: flex;
          cursor: pointer;
          margin-top: 8px;

          &:hover {
            background-color: rgba(255, 241, 237, 0.79);
          }
        }
      }

      .rememberText {
        margin-bottom: 16px;
      }

      .reminder {
        margin-bottom: 8px;
        font-size: 14px;
        color: #6B7280;
      }

      .note {
        display: flex;
        width: 100%;
        margin-top: 16px;
        padding: 16px;
        background-color: #FFFBF2;
        color: #8D6104;
        border-radius: 8px;
        border: 1px solid #FFEFCD;
        font-size: 14px;

        .noteIcon {
          flex-shrink: 0;
          margin-right: 12px;
        }

        .noteBody {
          text-align: left;
        }

        .noteTitle {
          margin-bottom: 8px;
          color: #8D6104;
          font-size: 13px;
          text-transform: uppercase;
          letter-spacing: .3px;
        }
      }

      .actionButtons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: auto;

        .actionButton {
          width: calc(50% - 12px);
          padding: 16px 0;
          border-radius: 8px;
          background-color: var(--grey100);
          font-size: 14px;
          text-align: center;
          color: var(--grey400);
          cursor: not-allowed;
          pointer-events: none;
        }

        .approveButton {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: var(--brand);
          color: var(--white);
          font-size: 16px;
          box-shadow: var(--button-boxShadow);

          &:hover {
            background-color: rgba(#DE6139, 0.9);
          }
        }

        .reviewButton {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          font-weight: 400;
          border: 1px solid #B73E3E;
          box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
          background: #fff;
          color: #B73E3E;
          padding: 16px;

          &:hover {
            border-color: rgba(#B73E3E, 0.75);
            color: rgba(#B73E3E, 0.75);
          }
        }

        .activeActionButton {
          pointer-events: auto;
          cursor: pointer;
        }

        .isZoom {
          background-color: #4285F4;
          box-shadow: 0 2px 8px rgba(66, 133, 244, 0.3);
          color: white;
          cursor: pointer;
          pointer-events: auto;

          &:hover {
            background-color: rgba(#4285F4, 0.9);
          }
        }

        .isAudiomovers {
          background-color: #05A8FF;
          box-shadow: 0 2px 8px rgba(5, 168, 255, 0.3);
          color: white;
          cursor: pointer;
          pointer-events: auto;

          &:hover {
            background-color: rgba(#05A8FF, 0.9);
          }
        }
      }

      .singleActionButton {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--brand);
        color: var(--white);
        font-size: 16px;
        width: 100%;
        cursor: pointer;
        box-shadow: var(--button-boxShadow);
        padding: 16px 0;
        border-radius: 8px;
        text-align: center;

        span {
          margin-left: 8px;
        }

        &:hover {
          background-color: var(--brand500);
        }
      }

      .sorryToInformYou {
        text-align: center;
        white-space: break-spaces;
        color: #6d727f;
        font-size: 14px;
        margin-bottom: 24px;
      }
    }
  }

}

.isHidden {
  display: none !important;
}

.warningNotification {
  margin-top: 12px;
}

.limitationWarningText {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
}

.warningDatedText {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  text-align: center;

  /* Grays/Grey 500 */

  color: #6B7280;
}

.memberInvitationText {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;

  /* Grays/Grey 500 */

  color: #6B7280;

  & a{
    cursor: pointer;
    text-decoration: underline;
  }
}