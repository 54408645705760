@import "src/styles/v2/variables";

.container {
  @include content-wrapper;
  position: relative;
  z-index: 2;
  height: fit-content;
  display: flex;
  flex-flow: column;

  .plans {
    margin: auto;
    display: flex;
    width: 1128px;
    justify-content: space-between;

    @include desktop-breakpoint {
      flex-flow: column;
      width: 100%;
      align-items: center;
      margin-top: -16px;
    }
  }

  .planContainer {
    width: 360px;
    height: 439px;
    border-radius: 6px;
    box-shadow: 0px 16px 16px -8px rgba(19, 19, 19, 0.15), 0px 4px 4px rgba(19, 19, 19, 0.15), 0px 2px 2px rgba(19, 19, 19, 0.15), inset 0px 0px 2px rgba(235, 234, 231, 0.5);
    background: $dark-600;
    display: flex;
    flex-flow: column;

    @include desktop-breakpoint {
      margin-bottom: 24px;
      width: 286px;
      height: fit-content;
    }

    .topBar {
      width: 100%;
      height: 56px;
      border: 1px solid #5C5C5C;;
      background: #1A1A1A;
      border-radius: 6px 6px 0 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .topBarContent {
        color: $light-400;
        @include text-medium;
        font-weight: 500;
        display: flex;
      }
    }

    .topBarDiscounted {
      background: #282317;

      .topBarContent {
        span {
          margin-left: 12px;
        }
        color: $brand-400;
      }
    }

    .planContent {
      padding: 48px;
      flex: 1;
      display: flex;
      flex-flow: column;
      align-items: center;

      @include desktop-breakpoint {
        padding: 32px 24px;
      }

      .title {
        @include headline-medium;
        font-size: 32px;
        line-height: 150%;
        text-align: center;
        color: $brand-400;
        margin-bottom: 7.5px;

        @include desktop-breakpoint {
          font-size: 24px;
          margin-bottom: 11px;
        }
      }

      .priceContainer {
        display: flex;

        .dollar {
          color: $light-700;
          @include text-headline;
          font-size: 24px;
          line-height: 29px;
          font-weight: 500;
          align-self: center;
          margin-top: 10px;
          width: 24px;
        }

        .price {
          color: $light-100;
          @include text-headline;
          font-size: 60px;
          line-height: 73px;
          font-weight: 500;

          @include desktop-breakpoint {
            font-size: 48px;
            line-height: 58px;
          }
        }

        .yr {
          color: $light-500;
          @include text-medium;
          font-weight: 500;
          align-self: flex-end;
          margin-bottom: 9px;

          @include desktop-breakpoint {
            margin-bottom: 4px;
          }
        }
      }

      .credits {
        color: $brand-400;
        @include text-medium;
        font-weight: 500;
      }

      .bullets {
        display: flex;
        flex-flow: column;
        margin: 24px 0 32px 0;

        .bullet {
          display: flex;
          align-items: center;

          span {
            color: $light-700;
            margin-left: 8px;
            @include text-small;
            line-height: 21px;
            font-weight: 500;
          }
        }
      }

      .button {
        font-weight: 700;
        font-size: 18px;

        @include desktop-breakpoint {
          font-size: 14px;
        }
      }
    }
  }


  @include desktop-breakpoint {
    .container {
      max-width: 100%;
      padding: 0 16px;
    }
  }

  .bottomBullet {
    color: $dark-100;
    @include text-medium;
    text-align: center;
    margin-top: 32px;

    @include tablet-breakpoint {
      margin-top: 16px;
      padding-bottom: 64px;
    }
  }
}
