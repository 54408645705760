/*
 * My Sessions
 */

 /* Navbar */
 .navbar {
     display: flex;
     height: 80px;
     justify-content: space-between;
     background-color: var(--grey900);
     margin-bottom: 32px;
 }

 .navbarNav {
     height: 100%;
 }

 .navbarNavLink {
     display: inline-flex;
     position: relative;
     align-items: center;
     height: 100%;
     color: var(--white);
     opacity: .5;
 }

 .navbarNavLink + .navbarNavLink {
     margin-left: 32px;
 }

 .navbarNavLink.isActive {
     opacity: 1;
 }

 .navbarNavLink.isActive:after {
     content: " ";
     position: absolute;
     bottom: 0;
     right: 0;
     left: 0;
     height: 4px;
     width: 100%;
     border-top-right-radius: 2px;
     border-top-left-radius: 2px;
     background-color: var(--brand);
 }

 .wrapper {
     min-height: auto;
     background-color: var(--grey100);
 }
 
 .container {
    max-width: 992px;
    margin: 0 auto;
    padding-bottom: 120px;
}

@media (max-width:992px) {
    .container {
        padding: 0 24px;
    }
}

 /* Header */
 .header {
     display: flex;
     align-items: center;
     justify-content: space-between;
     margin-bottom: 24px;
 }

 .headerTitle {
     display: inline-block;
     margin-right: auto;
     font-size: 18px;
     color: var(--grey900);
}

.headerNav {
    position: relative;
    margin-bottom: 24px;
}

.headerNav:after {
    content: " ";
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: var(--grey200);
    
}

.headerNavLink {
    position: relative;
    height: 100%;
    display: inline-block;
    padding-bottom: 16px;
    color: var(--grey500);
    cursor: pointer;
}

.headerNavLink.isActive {
    color: var(--grey800);
}

.headerNavLink.isActive:after {
    content: " ";
    height: 4px;
    width: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: var(--brand);
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
    z-index: 900;
}

.headerNavLink + .headerNavLink {
    margin-left: 24px;
}

.headerNavLink__counter {
    width: 24px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: var(--grey200);
    color: var(--grey600);
    border-radius: 16px;
    font-size: 14px;
}

@media (max-width:768px) {
    .headerButton {
        display: none;
    }
}

 /* Button */
 .button {
    width: 100%;
    padding: var(--button-padding);
    background-color: var(--button-background);
    border-radius: var(--button-radius);
    font-size: var(--fontsize-14);
    box-shadow: var(--button-boxShadow);
    color: var(--button-textColor);
    text-align: center;
    cursor: pointer;
    outline: none;
}

.button.fixed {
    width: initial;
    padding: var(--space-16) var(--space-24);
}

/* 
 * Sidepanel 
 */

 .sidepane__header {
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: 24px 32px;
   background-color: var(--grey100);
 }

 .sidepane__body {
   padding: 40px;
 }

 /* Empty State */

 .emptyState {
    max-width: 320px;
    margin: 120px auto 0 auto;
    text-align: center;
 }


 .emptyState__icon {
    margin-bottom: 12px;
 }

 .emptyState__title {
    margin-bottom: 8px;
    color: var(--grey700)
 }

 .emptyState__description {
    margin-bottom: 12px;
    color: var(--grey600)
 }

.spinner {
    transform: scale(2.5);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
