/*
 * Random global styles to different components, until we organize them the right way
 */

 .slide-pane {
  display: flex;
  flex-direction: column;
  background: #fff;
  min-width: 100px;
  height: 100%;
  box-shadow: -12px 0px 24px rgba(14, 15, 57, 0.05), -8px 0px 16px rgba(14, 15, 57, 0.05), -4px 0px 8px rgba(14, 15, 57, 0.05);
  transition: transform 0.35s;
  will-change: transform;
  z-index: 2000;
  width: 640px;
}

@media (max-width: 576px) {
   .slide-pane {
    width: 100% !important;
   }
}

.slide-pane.isLarge {
  width: 832px;
}

.ReactModal__Body--open {
  overflow-y: hidden;
}

.slide-pane:focus {
  outline-style: none;
}
.slide-pane_from_right {
  margin-left: auto;
  transform: translateX(100%);
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  overflow: auto;
}
.slide-pane_from_right.content-after-open {
  transform: translateX(0%);
}
.slide-pane_from_right.content-before-close {
  transform: translateX(100%);
}
.slide-pane_from_left {
  margin-right: auto;
  transform: translateX(-100%);
}
.slide-pane_from_left.content-after-open {
  transform: translateX(0%);
}
.slide-pane_from_left.content-before-close {
  transform: translateX(-100%);
}
.slide-pane_from_bottom {
  height: 90vh;
  margin-top: 10vh;
  transform: translateY(100%);
}
.slide-pane_from_bottom.content-after-open {
  transform: translateY(0%);
}
.slide-pane_from_bottom.content-before-close {
  transform: translateY(100%);
}
.slide-pane__overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0,0,0,0);
  z-index: 2000;
}
.slide-pane__overlay.overlay-after-open {
  background-color: rgba(0,0,0,0.3);
  transition: background-color 0.5s;
}
.slide-pane__overlay.overlay-before-close {
  background-color: rgba(0,0,0,0);
}
.slide-pane__header {
  display: flex;
  flex: 0 0 64px;
  align-items: center;
  background: #ebebeb;
  height: 64px;
  border-bottom: 1px solid rgba(0,0,0,0.1);
}
.slide-pane__title-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 32px;
  min-width: 0;
}
.slide-pane .slide-pane__title {
  font-size: 18px;
  font-weight: normal;
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0;
}
.slide-pane__close {
  margin-left: 24px;
  padding: 16px;
  opacity: 0.7;
  cursor: pointer;
}
.slide-pane__close svg {
  width: 12px;
  padding: 0;
}
.slide-pane__content {
  position: relative;
  overflow-y: auto;
  padding: 24px 32px;
  flex: 1 1 auto;
}
.slide-pane__subtitle {
  font-size: 12px;
  margin-top: 2px;
}



/* Selectbox */
.react-select__control {
  display: block;
  width: 100%;
  padding: 12px 0 12px 24px;
  background-color: var(--input-background) !important;
  border-radius: var(--radius-medium) !important;
  font-size: var(--fontsize-16) !important;
  color: var(--grey800) !important;
  border: 1px solid transparent !important;
}

.react-select__control--is-focused {
  border-color: var(--brand) !important;
  box-shadow: none !important;
}

.react-select__value-container {
  padding: 0 !important;
}

.react-select__placeholder {
  padding: 0 !important;
}

.react-select__dropdown-indicator {
  padding: 0 !important;
  margin-right: 16px;
}

.react-select__dropdown-indicator svg {
  width: 28px;
  height: 28px;
  fill: var(--grey400);
}

.react-select__option {
  color: #1F2937 !important;
}

.react-select__option--is-selected {
  background-color: #DE6139 !important;
  color: #fff !important;
}

.react-select__option--is-focused {
  background-color: #E5E7EB !important;
  color: #1F2937 !important;
}

/* Cookies Popup */

@media only screen and (min-width: 960px) {
  #hs-eu-cookie-confirmation {
    top: initial !important;
    bottom: 0 !important;
  }
}

/* Mobile Modal */
.mobileModal__close {
  position: absolute;
  top: 40px;
  right: 40px;
  padding: 12px;
  background-color: var(--grey200);
  border-radius: 8px;
  color: var(--grey700);
  font-weight: 600;
  outline: 0;
  cursor: pointer;
}

.mobileModal {
  width: calc(100% - 64px);
  margin: 120px auto 0 auto;
}

.mobileModal__icon {
  margin-bottom: 24px;
}

.mobileModal__title {
  margin-bottom: 8px;
  font-size: 22px;
  color: var(--grey700);
  letter-spacing: -.03em;
}

.mobileModal__description {
  font-size: 18px;
  color: var(--grey500);
  margin: 0 auto;
  margin-bottom: 48px;
  line-height: 150%;
}

/* Downgrade modal */

.downgradeModal__close {
  position: absolute;
  top: 40px;
  right: 40px;
  padding: 12px;
  background-color: var(--grey200);
  border-radius: 8px;
  color: var(--grey700);
  font-weight: 600;
  outline: 0;
  cursor: pointer;
}

.downgradeModal {
  width: calc(100% - 64px);
  max-width: 440px;
  margin: 32px auto 0 auto;
  text-align: left;
}

.downgradeModal__title {
  margin-bottom: 64px;
  font-size: 28px;
  color: #1a1f36;
  letter-spacing: -.03em;
}

.downgradeModal__currentHeading {
  padding-bottom: 16px;
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 16px;
  color: #1a1f36;
  border-bottom: 1px solid var(--grey200);
}

.downgradeModal__currentPlan {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid var(--grey200);
}

.downgradeModal__currentPlanTitle {
  margin-bottom: 8px;
  color: #1a1f36;
  font-size: 20px;
}

.downgradeModal__currentPlanDescription span {
  display: block;
}

.downgradeModal__currentPlanDue {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.downgradeModal__currentPlanDueTitle {
  font-weight: 400;
}

.downgradeModal__currentPlanActionsConfirm,
.downgradeModal__currentPlanActionsCancel {
  width: 100%;
  display: block;
  padding: 12px 0;
  text-align: center;
  cursor: pointer;
}

.downgradeModal__currentPlanActionsConfirm {
  margin-bottom: 16px;
  background-color: rgb(0, 116, 212);
  box-shadow: inset 0 0 0 1px rgba(50, 50, 93, 0.1), 0 2px 5px 0 rgba(50, 50, 93, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.07);
  border-radius: 6px;
  color: #fff;
}

.downgradeModal__currentPlanActionsCancel {
  margin-bottom: 12px;
  border-radius: var(--button-radius);
  color: rgb(60, 66, 87);
  background-color: #fff;
  /*box-shadow: 0 0 0 0 0, 0 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 2px 5px 0px;*/
}

.downgradeModal__currentPlanActions p {
  color: #697386;
  font-size: 14px;
}

.downgradeModal__currentPlanActions p a {
  color: #1a1f36;
}

/* Plans */

.mobileModal__plans__planTitle {
  margin-bottom: 12px;
  font-size: 22px;
  letter-spacing: .3px;
}

.mobileModal__plans__body {
  display: flex;
}

.mobileModal__plans__plan {
  text-align: left;
  padding: 24px;
  border-radius: 8px;
  background-color: var(--white);
  box-shadow: 0px 2px 16px rgba(14, 15, 57, 0.05), 0px 2px 8px rgba(14, 15, 57, 0.05);
}

.mobileModal__plans__plan + .billingModal__plans__plan {
  margin-left: 24px;
}

.mobileModal__plans__planList {
  margin-bottom: 16px;
}

.mobileModal__plans__planList li {
  margin-bottom: 8px;
  color: var(--grey700);
}

.mobileModal__plans__planButton {
  width: 100%;
  margin-top: 18px;
  padding: var(--button-padding);
  background-color: var(--button-background);
  border-radius: var(--button-radius);
  font-size: var(--fontsize-14);
  box-shadow: var(--button-boxShadow);
  color: var(--button-textColor);
  text-align: center;
  cursor: pointer;
  outline: none;
}

.mobileModal__plans__planButton.isCurrent {
  background-color: var(--grey200);
  color: var(--grey600);
  box-shadow: none;
  cursor: default;
}


@media (max-width: 576px) {
  #hubspot-messages-iframe-container {
    display: none !important;
  }
}

/* Range slider */
.rangeslider {
  background-color:  var(--brand100) !important;
  box-shadow:  none !important;
}

.rangeslider-horizontal .rangeslider__fill{
  background-color:  var(--brand) !important;
  box-shadow:  none !important;
}

.rangeslider-horizontal .rangeslider__handle {
  border-color: transparent !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .02), 0 -1px 3px rgba(0, 0, 0, .02) !important;
}

.rangeslider .rangeslider__handle {
  box-shadow: 0 1px 3px rgba(0, 0, 0, .02), 0 -1px 3px rgba(0, 0, 0, .02) !important;
}

.rangeslider-horizontal .rangeslider__handle:after {
  background-color:  var(--brand) !important;
  box-shadow: none !important;
  top: 7px !important;
  left: 7px !important;
}

.ReactModal__Content {
  overflow-y: auto !important;
}
