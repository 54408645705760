.overlay {
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 999999;
  opacity: 0.8;
  pointer-events: none;

  .spinner {
    transform: scale(2.5);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}
