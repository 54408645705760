@import "src/styles/v2/variables";

.content {
  display: flex;
  // justify-content: center;
  width: 100%;
  border-radius: 8px;
  background: #FCEBB8;

  .wrapper {
    box-sizing: border-box;

    /* Auto layout */
    
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    // gap: 12px;

    @include mobile-breakpoint {
      font-size: 11px;
      gap: 0px;
    }

    .description {
      @include mobile-breakpoint {
        justify-content: left;
        display: flex;
        flex-flow: column;
        width: 200px;
      }
      
      .totalCredits {
        padding-left: 4px;
      }
    }

    a {
      color: #866220;
      text-decoration: underline;
      padding-left: 4px;
    }

    p {
      color: #866220;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      @include mobile-breakpoint {
        font-size: 11px;
      }
    }

    .icon {
      padding-top: 4px;
      margin-right: 12px;
    }
  }
}
