.overlay {
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
  pointer-events: none;
  display: flex;
  align-items: flex-end;

  .container {
    position: relative;
    margin-left: auto;
    width: fit-content;
    display: flex;
    flex-flow: column-reverse;
    height: fit-content;
    padding: 24px 24px 16px 24px;
    pointer-events: auto;

    .hideButton {
      pointer-events: auto;
      margin-left: auto;
      padding-right: 4px;
      cursor: pointer;
      margin-top: 4px;
      font-size: 14px;

      &:hover {
        color: #6B7280;
      }
    }

    .uploadCard {
      padding: 12px;
      border-radius: 8px;
      box-shadow: 0 2px 16px rgb(14 15 57 / 5%), 0 2px 8px rgb(14 15 57 / 5%);
      margin-bottom: 12px;
      width: 360px;
      height: 88px;
      border: 1px solid var(--grey700);
      font-family: "Inter", sans-serif;
      pointer-events: auto;
      display: flex;
      font-size: 13px;
      cursor: pointer;
      position: relative;
      background-color: var(--grey50);

      &:hover {
        .closeButton {
          display: block;
        }
      }

      .closeButton {
        position: absolute;
        top: -8px;
        right: -4px;
        transform: scale(1.25);
        display: none;
      }

      .content {
        display: flex;
        flex-flow: column;
        flex: 1;

        p {
          margin-bottom: 4px;
          font-weight: 500;
        }

        span {
          font-size: 12px;
        }
      }

      .spinner {
        transform: scale(1.25);
        margin: auto;
      }

      .icon {
        margin: auto;
      }

      .tick {
        margin-right: 12px;
      }

      &:first-of-type {
        margin-bottom: 0;
      }
    }
  }

  .bubble {
    position: absolute;
    right: -1px;
    bottom: 36px;
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--grey700);
    border-radius: 8px;
    box-shadow: 0 2px 16px rgb(14 15 57 / 5%), 0 2px 8px rgb(14 15 57 / 5%);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    pointer-events: auto;
    cursor: pointer;

    .spinner {
      transform: scale(1.15);
      margin: auto;
    }
  }
}

.isReadyToGo {
  background-color: #F0F6F6;
  color: #44837D;
}

.isFailed {
  background-color: #FCF3F0;
  color: var(--danger);
}

.tick {
  margin: auto;
  transform: scale(2.75);
}
