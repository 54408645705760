/* Plans */
.plans__body {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}

.switchPlanExplanation {
  font-weight: 300;
  font-style: italic;
  font-size: 13px;
  padding-top: 20px;
}

.paymentMethodModal {
  width: 80%;
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: auto;

  h1 {
    position: relative;
    top: -8px;
    color: var(--grey400);
    margin-bottom: 16px;
    font-size: 18px;
  }

  .methods {
    display: flex;
    justify-content: center;

    .method {
      width: 180px;
      height: 144px;
      background: var(--grey200);
      border-radius: 8px;
      border: 1px solid var(--grey200);
      box-shadow: 0 2px 16px rgb(14 15 57 / 5%), 0 2px 8px rgb(14 15 57 / 5%);
      margin: 0 16px;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover:not(.methodSelected) {
        background-color: var(--grey100);
      }

      p {
        font-family: "Inter", sans-serif;
        margin-bottom: 22px;
        font-size: 20px;
      }
    }

    .methodSelected {
      border: 1px solid var(--brand);
      background-color: #FFF1ED;
      cursor: auto;
    }
  }

  .joinButton {
    width: calc(100% - 16px);
    margin-top: 24px;
    padding: var(--button-padding);
    background-color: var(--button-background);
    border-radius: var(--button-radius);
    font-size: var(--fontsize-16);
    box-shadow: var(--button-boxShadow);
    color: var(--button-textColor);
    text-align: center;
    cursor: pointer;
    outline: none;
    font-family: "Inter", sans-serif;
  }
}

.rolloverWarningModal {
  width: 80%;
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: auto;

  h1 {
    position: relative;
    top: -8px;
    color: var(--grey400);
    margin-bottom: 16px;
    font-size: 18px;
  }

  .rolloverInfoContainer {
    text-align: left;
    margin-bottom: 8px;
    border: none;
  }

  .joinButton {
    width: calc(100% - 16px);
    margin-top: 8px;
    padding: var(--button-padding);
    background-color: var(--button-background);
    border-radius: var(--button-radius);
    font-size: var(--fontsize-16);
    box-shadow: var(--button-boxShadow);
    color: var(--button-textColor);
    text-align: center;
    cursor: pointer;
    outline: none;
    font-family: "Inter", sans-serif;
  }
}

// downgrade modal rollover content

.rolloverInfoContainer {
  display: flex;
  flex-flow: column;
  padding: 16px 0;
  margin-bottom: 16px;
  border-top: 1px solid var(--grey200);

  span {
    color: var(--grey600);
    font-style: italic;
  }

  span:first-of-type {
    margin-bottom: 8px;
  }
}

.confirmButton {
  width: 100%;
  display: block;
  text-align: center;
  cursor: pointer;
  margin-bottom: 16px;
  padding: var(--button-padding);
  background-color: var(--button-background);
  border-radius: var(--button-radius);
  font-size: var(--fontsize-16);
  box-shadow: var(--button-boxShadow);
  color: var(--button-textColor);
  outline: none;
  font-family: "Inter", sans-serif;
}