.container {
  padding: 16px 24px;
  border-radius: 8px;
  box-shadow: 0 2px 16px rgb(14 15 57 / 5%), 0 2px 8px rgb(14 15 57 / 5%);
  margin-bottom: 12px;
  width: 100%;
  background-color: #FFFBF2;
  border: 1px solid #FFEFCD;
  color: var(--grey700);
  font-family: "Inter", sans-serif;

  h1 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  ul {
    list-style: inside;
    margin-bottom: 8px;
    color: var(--grey700);

    li {
      line-height: 24px;
    }
  }

  p {

  }
}

.containerV2 {
  display: flex;
  flex-direction: column;
  gap: 12px;

  padding: 16px 24px;
  border-radius: 8px;
  box-shadow: 0px 2px 16px rgba(14, 15, 57, 0.05);
  margin-bottom: 12px;
  width: 100%;
  background-color: #FFFFFF;
  border: 1px solid #FFFFFF;
  font-family: "Inter", sans-serif;

  .header {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 21px */
    color: #374151;
  }

  .footer {
    font-size: 14px;
  }

  ul {
    list-style: inside;
    margin-bottom: 8px;
    color: #866220;

    li {
      line-height: 24px;
    }
  }

  p {

  }
}

.benefits {
  display: flex;
  flex-direction: row;
  grid-gap: 36px;
  gap: 36px;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
}

.benefitsCol {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  align-content: center;
}

.benefitItem {
  border: #374151;
}