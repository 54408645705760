@import "src/styles/v2/variables";

.availabilityCalendar {
  .description {
    text-align: center;
    font-weight: 500;
    color: var(--grey500);
    font-size: 14px;
    letter-spacing: -0.3px;
    white-space: break-spaces;
    margin: 8px 0 36px 0;

    @include mobile-breakpoint {
      text-align: left;
      white-space: initial;
    }
  }
}

.availabilityCalendarPagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.availabilityCalendarPagination__title {
  color: var(--grey600);
}

.availabilityCalendarPagination__button {
  font-size: 0;
  padding: 12px 16px;
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid var(--grey300);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

.availabilityCalendarPagination__buttonDisabled {
  font-size: 0;
  padding: 12px 16px;
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid var(--grey200);
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
  cursor: not-allowed;
}

.availabilityCalendarPagination__buttonDisabled svg path {
  stroke: var(--grey200);
}

/* Slots Picker */
.slotsTable {
  display: flex;
  margin-bottom: 22px;
}

.slotsTable__column {
  padding: 16px 8px;
  border-radius: 8px;
  background-color: var(--grey50);
  width: 14.285%;
}

.slotsTable__column + .slotsTable__column {
  margin-left: 8px;
}

.slotsTable__columnHead {
  margin-bottom: 12px;
}

.slotsTable__dayName {
  color: var(--grey400);
}

.slotsTable__dayNumber {
  color: var(--grey600);
  font-size: 18px;
}

.slotsTable__slot {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  margin-bottom: 8px;
  border-radius: 8px;
  background-color: var(--white);
  font-size: 13px;
  box-shadow: 0px 1px 4px rgba(14, 15, 57, 0.05), 0px 1px 2px rgba(14, 15, 57, 0.05);
  cursor: pointer;
  border: 1px solid transparent;
  font-weight: 500;
  position: relative;

  .slotType {
    position: absolute;
    bottom: -1px;
    right: 4px;
    font-size: 9px;
    color: var(--grey800);
  }

  .fixedSlot {
    color: var(--brand);
  }
}

.slotNotSelectable {
  cursor: auto;
}

.slotsTable__slot.isSelected {
  background-color: #FFF5F2;
  border-color: var(--brand);
  color: var(--brand);
}

.slotsTable__slot.isSelected .slotsTable__slotTime,
.slotsTable__slot.isSelected .slotsTable__slotPeriod {
  color: var(--brand);
}

.slotsTable__slotTime {
  color: var(--grey600);
}

.slotsTable__slotPeriod {
  color: var(--grey400);
  text-transform: uppercase;
}

.prebooked {
  background-color: #FFF5F2;
  border-color: var(--brand);
  color: var(--brand);
}

/* Different States of the timeslot */
.prebookedAsBooked,
.slotsTable__slot.booked,
.slotsTable__slot.booked .slotsTable__slotTime,
.slotsTable__slot.booked .slotsTable__slotPeriod {
  background-color: var(--grey100);
  color: var(--grey400);
  text-decoration: line-through;
  box-shadow: none;
  cursor: not-allowed;
}

.prebookedAsBooked {
  border: none;

  .slotsTable__slotTime {
    color: var(--grey400);
  }
}

.changeWeek { 

}

.slotsTable {

}

.booked {
  color: red;
}

.availabilityCalendar__timezone {
  display: flex;
  align-items: center;
  margin-top: 8px;
  border-radius: 12px;
  font-size: 14px;
  color: var(--grey700);

  p {
    margin-left: 8px;
  }
}

.availabilityCalendar__timezoneIcon {
  font-size: 0;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.availabilityCalendar__note {
  display: block;
  width: 100%;
  padding: 80px 0;
  text-align: center;
}

.availabilityCalendar__noteIcon {
  margin-bottom: 12px;
}

.availabilityCalendar__noteText {
  color: var(--grey700);
}

/* Mobile */

@media (max-width: 992px) {
  .availabilityCalendar {
    width: 40%;
  }

  .slotsTable__column {
    margin-left: 8px;
    width: 100%;
  }
}

@media (max-width: 576px) {
  .availabilityCalendar {
    width:  100%;
  }

  .slotsTable__slot {
    margin-bottom:  12px;
    padding: 16px 12px 16px 24px;
    font-size: 16px;
    justify-content: normal;
    box-shadow: 0px 1px 4px rgba(14, 15, 57, 0.1), 0px 1px 2px rgba(14, 15, 57, 0.1);
  }

  .slotsTable__slotTime {
    margin-right:  4px;
  }
}

.isFullWidth {
  width: 100%;
  position: relative;
}

