.container {
  width: 100%;
  background: var(--grey50);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 8px;
  display: flex;

  .avatar {
    margin-right: 16px;
  }

  .body {
    flex: 1;
    display: flex;
    flex-flow: column;

    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      color: #83888f;
      font-size: 12px;

      .name {
        color: var(--grey800);
        font-size: 16px;
        font-weight: 700;
      }
    }

    .message {
      flex: 1;
      white-space: break-spaces;
      word-break: break-word;
    }
  }
}