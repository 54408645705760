.externalNote {
  display: flex;
  width: 100%;
  padding: 24px;
  background-color: #FFFBF2;
  color: #8D6104;
  border-radius: 8px;
  border: 1px solid #FFEFCD;
  margin: 16px 0;

  .externalNoteIcon {
    flex-shrink: 0;
    margin-right: 12px;
  }

  .externalNoteBody {
    text-align: left;
    display: flex;
    flex-flow: column;
    justify-content: center;

    a {
      color: #8D6104;
      font-weight: 600;
      text-decoration: underline;
    }

    .externalNoteTitle {
      margin-bottom: 8px;
      color: #8D6104;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: .3px;
      align-self: flex-start;
    }

    p {
      font-size: 16px;
      font-weight: 600;
      line-height: 19px;
      white-space: break-spaces;
      color: #866220;
    }
  }
}
