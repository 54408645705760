.title {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  text-align: center;
  align-self: center;
  color: var(--grey800);
  margin-bottom: 24px;
}

.checkbox {
  margin-bottom: 6px;
}

.checkbox {
  position: relative;
  padding: 16px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
  background-color: var(--grey50);
  border: 1px solid var(--grey50);
  width: 100%;
  border-radius: 8px;
}

.label {
  padding-left: 19px;
  line-height: 20px;
  color: #666;
  text-align: left;
}

.checked {
  border: 1px solid var(--brand);
  background-color: var(--white);
}

textarea {
  min-height: 120px;
  width: 100%;
  margin: 6px auto 0 auto;
  resize: vertical;
  padding: var(--input-padding);
  background-color: var(--input-background);
  border-radius: var(--radius-medium);
  font-size: var(--fontsize-16);
  color: var(--grey800);
  outline: none;
}

textarea:focus {
  background-color: var(--white);
  border-radius: var(--radius-medium);
  outline: none;
  border-color: var(--brand);
}

.buttons {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;

  .nextButton {
    width: 112px;
  }
}