@import "src/styles/v2/variables";

.modal.modal {
  width: 70vw;
  max-width: 501px;
  height: fit-content;
  display: flex;
  flex-flow: column;
  padding: 24px;
  position: relative;

  @include mobile-breakpoint {
    width: 90vw;
  }

  .container {
    display: flex;
    flex-flow: column;
    flex: 1;
  }
}