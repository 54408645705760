.title {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  text-align: center;
  align-self: center;
  color: var(--grey800);
  margin-bottom: 24px;
  padding: 0 24px;
}

.buttons {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;

  .nextButton {
    width: 112px;
  }
}