@import "src/styles/v2/variables";

.modal.modal {
  width: 70vw;
  max-width: 456px;
  height: fit-content;
  display: flex;
  flex-flow: column;
  padding: 24px;
  position: relative;

  @include mobile-breakpoint {
    width: 90vw;
  }

  .title {
    text-align: center;
    color: $black;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    margin: 24px 0;
  }

  .input {
    height: 58px;
    font-weight: 400;
  }

  .label {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: var(--grey800);
    text-align: left;
  }

  .row {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 8px;
  }

  .marginBottom {
    margin-bottom: 16px;
  }

  .button {
    width: 100%;
    height: 58px;
    font-size: 16px;
  }
}
