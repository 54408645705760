.confirmation__calendarButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 16px;
  padding: 12px 18px 12px 16px;
  border-radius: 8px;
  background-color: transparent;
  border: 1px solid var(--grey200);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  color: var(--grey600);
  cursor: pointer;
}

.confirmation__calendarButton svg {
  margin-right: 12px;
}