
/*
 * Input Component 
 */
 
 .profileSecurity__label {
  display: block;
  font-size: 16px;
  color: var(--grey700);
  margin-bottom: 32px;
}

.profileSecurity__label.mbSmall {
  margin-bottom: 4px;
}

.profileSecurity__label.mtMedium {
  margin-top: 32px;
}

.profileSecurity__forgot {
  display: block;
  font-size: var(--fontsize-14);
  color: var(--grey500)
}

.profileSecurity__input {
  display: block;
  width: 100%;
  padding: 16px 24px;
  margin-top: 4px;
  background-color: var(--input-background);
  border-radius: var(--radius-medium);
  font-size: var(--fontsize-16);
  color: var(--grey800);
  border: 1px solid var(--grey300);
  outline: none;
}

.profileSecurity__input:focus {
  background-color: var(--white);
  border-radius: var(--radius-medium);
  outline: none;
  border-color: var(--brand);
}
