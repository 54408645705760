.howItWorkContainer {
  padding: 0 32px;
  margin-bottom: 52px;

  .title {
    font-size: 24px;
    font-weight: 600;
  }

  .titleDescription {
    margin: 0 auto 32px auto;
    color: var(--grey600);
    font-size: 16px;
  }

  .stepContainer {
    display: flex;
    width: 100%;
    min-height: 80px;
    height: fit-content;
    margin-bottom: 2.4rem;
    position: relative;

    &:last-of-type {
      margin-bottom: 0;

      .numberContainer:after {
        display: none;
      }
    }

    .numberContainer {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: var(--brand);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 12px;
      flex-shrink: 0;

      &:after {
        content: '';
        position: absolute;
        width: 4px;
        background: var(--brand);
        height: calc(100% + 16px);
        margin: 0 auto;
        left: 14px;
        top: 32px;
      }

      span {
        font-size: 18px;
        font-weight: 400;
        color: white;
      }
    }

    .description {
      display: flex;
      flex-flow: column;
      text-align: start;

      h3 {
        font-size: 16px;
      }

      span {
        font-size: 14px;
      }
    }
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 0 32px 32px 32px;
}

.back {
  font-size: var(--fontsize-16);
  color: var(--grey600);
  cursor: pointer;
  background: transparent;
}

.button {
  padding: var(--button-padding);
  background-color: var(--button-background);
  border-radius: var(--button-radius);
  font-size: var(--fontsize-14);
  box-shadow: var(--button-boxShadow);
  color: var(--button-textColor);
  text-align: center;
  cursor: pointer;
  outline: none;
  width: 112px;
  align-self: flex-end;
  margin-left: auto;
  height: 49px;
}