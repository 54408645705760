/*
 * Toast Component 
 */
 
.toast {
  position: fixed;
  bottom: 64px;
  right: 64px;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  min-width: 320px;
  border-radius: 8px;
  background-color: var(--white);
  box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.10), 0px 24px 64px rgba(0, 0, 0, 0.10);
  z-index: 999999;
}

.toast__content {
  display: flex;
}

.toast__icon {
  font-size: 0;
  margin-right: 12px;
}

.toast__text {
  margin-right: 64px;
  color: var(--grey600);
}

.toast__close {
  font-size: 0;
  margin: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;
}
