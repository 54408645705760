.container {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 12px;

  .input {
    width: 100%;
    padding: 12px;
    border-radius: var(--radius-medium);
    font-size: var(--fontsize-14);
    color: var(--grey800);
    border: 1px solid var(--grey300);
    outline: none;
  }

  .input:focus {
    background-color: var(--white);
    border-radius: var(--radius-medium);
    outline: none;
    border-color: var(--brand);
  }

  .icons {
    display: flex;
    align-items: center;
    position: absolute;
    right: 12px;
    top: 12px;
    margin: auto;

    .closeIcon {
      transform: scale(0.65);
      cursor: pointer;
      margin-right: 4px;
    }

    .dropdownIcon {
      cursor: pointer;
    }
  }
}
