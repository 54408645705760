@import "src/styles/v2/variables";

.modal.modal {
  width: 70vw;
  max-width: 456px;
  height: fit-content;
  display: flex;
  flex-flow: column;
  padding: 36px 0 24px 0;
  position: relative;

  @include mobile-breakpoint {
    width: 90vw;
  }

  .counter {
    position: absolute;
    top: -12px;
    left: 24px;
    color: var(--grey500);
    font-size: 14px;
    line-height: 21px;
  }

  .container {
    display: flex;
    flex-flow: column;
    flex: 1;
    padding: 0 24px;

    h3 {
      color: var(--grey800);
      font-weight: 700;
      font-size: 16px;
      margin-top: 8px;
      text-align: center;
      margin-bottom: 24px;
    }

    .description {
      color: var(--grey500);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 17px;
      text-align: center;
      margin-bottom: 28px;
    }

    .checkbox {
      margin-bottom: 24px;
      align-items: flex-start;

      span {
        align-self: flex-start;
        position: relative;
        top: 4px;
      }

      .checkboxLabel {
        text-align: left;
        padding-left: 12px;
        font-size: 14px;
        color: var(--grey800);
        font-weight: 500;
        top: 0;
      }
    }

    .button {
      margin-top: 24px;
      width: 100%;
    }
  }

  .reason {
    display: flex;
    margin-bottom: 24px;

    .icon {
      width: 20px;
    }

    span {
      margin-left: 16px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: 0.01em;
      text-align: left;
    }
  }

  .restartButton {
    width: 100%;
    margin-top: 56px;
  }
}