@import "variables";

$path: '../../../assets/fonts/';

// Tiempos
$tiempos-font-path: '#{$path}tiempos/' !default;

@font-face {
  font-family: 'Tiempos';
  font-weight: 300;
  font-style: normal;
  src: url('../../../assets/fonts/tiempos/tiempos-headline-web-light.woff2') format('woff2');
}

@font-face {
  font-family: 'Tiempos';
  font-weight: 400;
  font-style: normal;
  src: url('../../../assets/fonts/tiempos/tiempos-headline-web-regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Tiempos';
  font-weight: 500;
  font-style: normal;
  src: url('../../../assets/fonts/tiempos/tiempos-headline-web-medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Tiempos';
  font-weight: 600;
  font-style: normal;
  src: url('../../../assets/fonts/tiempos/tiempos-headline-web-semibold.woff2') format('woff2');
}

@font-face {
  font-family: 'Tiempos';
  font-weight: 700;
  font-style: normal;
  src: url('../../../assets/fonts/tiempos/tiempos-headline-web-bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Tiempos';
  font-weight: 700;
  font-style: italic;
  src: url('../../../assets/fonts/tiempos/tiempos-headline-web-bold-italic.woff2') format('woff2');
}

// Maison-Neue
$maison-neue-font-path: '#{$path}maison-neue/' !default;

@font-face {
  font-family: 'Maison Neue';
  font-weight: 400;
  font-style: normal;
  src: url('../../../assets/fonts/maison-neue/MaisonNeueWEB-Book.woff2') format('woff2')
}

@font-face {
  font-family: 'Maison Neue';
  font-weight: 500;
  font-style: normal;
  src: url('../../../assets/fonts/maison-neue/MaisonNeueWEB-Medium.woff2') format('woff2')
}

@font-face {
  font-family: 'Maison Neue';
  font-weight: 600;
  font-style: normal;
  src: url('../../../assets/fonts/maison-neue/MaisonNeueWEB-Demi.woff2') format('woff2')
}

@font-face {
  font-family: 'Maison Neue';
  font-weight: 700;
  font-style: normal;
  src: url('../../../assets/fonts/maison-neue/MaisonNeueWEB-Bold.woff2') format('woff2')
}

