@import "../../../../styles/v2/variables";

/* Onboarding Modal */

.modal.modal {
  width: 70vw;
  max-width: 560px;
  height: fit-content;
  display: flex;
  flex-flow: column;
  padding: 36px 0 0 0;
  position: relative;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08), 0 4px 18px rgba(0, 0, 0, .08), 0 2px 16px -16px rgba(0, 0, 0, 0.08);

  @include mobile-breakpoint {
    width: 90vw;
  }
}

.title {
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: 600;

  @include mobile-breakpoint {
    margin: auto;
    text-align: center;
    width: 80%;
    margin-bottom: 16px;
  }
}

.description {
  margin: 0 auto 32px auto;
  color: var(--grey600);
  font-size: 16px;

  @include mobile-breakpoint {
    padding: 0 16px;
  }
}

.button {
  padding: var(--button-padding);
  background-color: var(--button-background);
  border-radius: var(--button-radius);
  font-size: var(--fontsize-14);
  box-shadow: var(--button-boxShadow);
  color: var(--button-textColor);
  text-align: center;
  cursor: pointer;
  outline: none;
}

.buttonStart {
  margin: 0 auto;
  padding-left: 32px;
  padding-right: 32px;
}

.header {
  display: flex;
  padding: 24px 32px 0 40px;
  justify-content: space-between;
}

.close {
  background: transparent;
  cursor: pointer;
  padding: 0;
}

.counter p {
  color: var(--grey500);
  font-size: 16px;
}

.video {
  margin-bottom: 24px;
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 0 32px 32px 32px;
}

.back {
  font-size: var(--fontsize-16);
  color: var(--grey600);
  cursor: pointer;
  background: transparent;
}

.helpTitle {
  margin-bottom: 24px;
}

.helpOptions {
  margin-bottom: 32px;
  padding: 0 40px;
  text-align: left;
}

.helpOptionsItem {
  margin-bottom: 24px;
}

.helpOptionsItem h3 {
  margin-bottom: 8px;
  color: var(--grey800);
  font-weight: 500;
}

.helpOptionsItem p {
  color: var(--grey500);
}

.linkIcon {
  display: inline-block;
  margin-left: 8px;
}
