@import "styles/v2/variables";

/*
 * Navbar Component 
 */

.navbar {
  display: flex;
  align-items: center;
  height: 80px;
  padding: 0 32px;
  justify-content: space-between;
  background-color: var(--grey900);
  margin-bottom: 32px;
  position: sticky;
  z-index: 2000;
  @media only screen and (max-width: $tablet) {
    padding: 0 16px;
  }

  @include mobile-breakpoint {
    height: 64px;
  }
}

.navbar.noMargin {
  margin-bottom: 0;
}

.mobileMargin {
  @media only screen and (max-width: $tablet) {
    margin-bottom: 64px !important;
  }
}

.navbarLogo {
  display: flex;
  align-items: center;
  width: 36px;
}

.navbarNav {
  height: 80px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: fit-content;

  @include mobile-breakpoint {
    height: 64px;
  }
}

.navbarNavLink {
  display: inline-flex;
  position: relative;
  align-items: center;
  height: 100%;
  color: var(--white);
  opacity: .5;
}

.navbarNavLink + .navbarNavLink {
  margin-left: 32px;
}

.navbarNavLink.isActive {
  opacity: 1;
}

.navbarNavLink.isActive:after {
  content: " ";
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 4px;
  width: 100%;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
  background-color: var(--brand);
}
/* Dropdown */
.dropdown__container {
  position: relative;
  display: flex;
}

.upload {
  display: flex;
  align-items: center;
  margin-right: 24px;
  color: var(--button-textColor);
  text-transform: capitalize;
  font-size: 14px;

  .spinner {
    margin-right: 4px;
  }
}

.getFullAccessButton {
  margin: auto;
  margin-right: 16px;
  padding: var(--button-padding);
  background-color: var(--button-background);
  border-radius: var(--button-radius);
  font-size: var(--fontsize-16);
  box-shadow: var(--button-boxShadow);
  color: var(--button-textColor);
  text-align: center;
  cursor: pointer;
  outline: none;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: $tablet) {
    position: absolute;
    right: 0;
    margin-right: 0;
    top: 75px;
    width: 144px;
  }
}

.dropdown__trigger {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  padding: 0;
  border-radius: 100%;
  background-color: #333A4A;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  outline: 0;
}

.dropdown__menu {
  position: absolute;
  top: 60px;
  right: 0;
  background: var(--white);
  border-radius: 8px;
  overflow: hidden;
  min-width: 220px;
  box-shadow: 0px 0px 8px rgba(14, 15, 57, 0.1), 0px 0px 4px rgba(14, 15, 57, 0.1);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.15s ease, transform 0.15s ease, visibility 0.15s;
  z-index: 2000;
}

.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.dropdown__menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown__menu li {
  border-bottom: 1px solid var(--grey200);
}

.dropdown__menu li:last-child {
  border-bottom: none;
}

.dropdown__menu li a {
  display: flex;
  align-items: center;
  font-size: 14px;
  text-decoration: none;
  color: var(--grey600);
  padding: 15px 20px;
  cursor: pointer;
}

.dropdown__menu li a:hover {
  background-color: var(--grey100);
}

.dropdown__menuItemIcon {
  margin-right: 8px;
  display: block;
  font-size: 0;
}

.contactSupportIcon {
  transform: scale(1.25);
  position: relative;
  top: 2px;
}

.creditsDisplay {
  align-items: center;
  padding: 10px;
  padding-right: 15px
}

.mobileCreditsDisplay {
  margin-left: 22px;
  margin-right: 22px;
  margin-top: 10px;
  margin-bottom: 10px;
}