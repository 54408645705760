@import "src/styles/v2/variables";

.content {
  display: flex;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #D1D5DB;
  margin: 16px 0;

  .wrapper {
    text-align: left;
    display: flex;
    flex-flow: row;
    justify-content: center;
    font-weight: 200;

    .description {
      color: #374151;
      @include mobile-breakpoint {
        justify-content: left;
        display: flex;
        flex-flow: column;
        width: 200px;
      }
      
      .totalCredits {
        padding-left: 4px;
        
      }
    }

    a {
      color: #9CA3AF;
      text-decoration: underline;
      padding-left: 4px;
    }

    p {
      color: #9CA3AF;
    }

    .preSessionSpecsIcon {
      margin-right: 12px;
      margin-top: 1px;
      path {
        stroke: #DE6139;
      }
    }
  }
}
