@import "src/styles/v2/variables.scss";

.wrapper {
  min-height: auto;
  background-color: var(--grey100);
}

.container {
  max-width: 768px;
  margin: 0 auto;
  padding-bottom: 120px;
}

@media (max-width: 768px) {
  .container {
    margin: 0 24px;
  }
}

.profileContentSmall {
  width: 60%;
}

@media (max-width: 768px) {
  .profileContentSmall {
    width: 100%;
  }
}

.profileContentLarge {
  width: 100%;
}

.profileInfoButton {
  position: fixed;
  right: 32px;
  bottom: 32px;
  height: 64px;
  width: 64px;
  background: var(--brand);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: var(--button-boxShadow);
  color: var(--white);
  font-style: italic;
  font-family: "Georgia";
  font-size: 28px;
  cursor: pointer;
}

.profileInfoButton:hover {
  background: var(--brand500);
}


/* Navbar */
.navbar {
  display: flex;
  height: 80px;
  justify-content: space-between;
  background-color: var(--grey900);
  margin-bottom: 32px;
}

.navbarNav {
  height: 100%;
}

.navbarNavLink {
  display: inline-flex;
  position: relative;
  align-items: center;
  height: 100%;
  color: var(--white);
  opacity: .5;
}

.navbarNavLink.isActive {
  opacity: 1;
}

.navbarNavLink.isActive:after {
  content: " ";
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 4px;
  width: 100%;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
  background-color: var(--brand);
}

/* Header */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.headerTitle {
  font-size: 18px;
  color: var(--grey900);
  z-index: 2;
  font-family: "Inter", sans-serif;
  line-height: 22px;
  font-weight: 800;
}

.headerNav {
  position: relative;
  margin-bottom: 24px;
  display: flex;
  flex-wrap: wrap;
}

.headerNav:after {
  content: " ";
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--grey200);

}

.headerNavLink {
  position: relative;
  height: 100%;
  display: inline-block;
  padding-bottom: 16px;
  color: var(--grey500);
  cursor: pointer;
  margin-right: 24px;

  @include mobile-breakpoint {
    padding: 8px 0;
  }
}

.headerNavLink.isActive {
  color: var(--grey800);
}

.headerNavLink.isActive:after {
  content: " ";
  height: 4px;
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--brand);
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
  z-index: 1;
}
