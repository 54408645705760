.container {
  width: 90%;
  max-width: 1400px;
  margin: 64px auto 0 auto;

  .close {
    position: absolute;
    top: 40px;
    right: 40px;
    padding: 12px;
    background-color: var(--grey200);
    border-radius: 8px;
    color: var(--grey700);
    font-weight: 600;
    outline: 0;
    cursor: pointer;
  }

  .title {
    margin-bottom: 8px;
    font-size: 28px;
    color: var(--grey700);
    letter-spacing: -.03em;
  }

  .description {
    width: 512px;
    white-space: break-spaces;
    text-align: center;
    margin: 48px auto 0 auto;
  }
}

@media (max-width: 768px) {
  .description {
    padding: 0 16px;
    width: fit-content;
    white-space: break-spaces;
    text-align: center;
    margin: 32px auto 0 auto;
  }
}
