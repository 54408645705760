@import "src/styles/v2/variables";

.container {
  display: flex;
  flex-flow: column;
  margin-bottom: 24px;

  .group {
    padding: 16px 24px;
    border-radius: 8px;
    background-color: var(--white);
    box-shadow: 0 2px 16px rgb(14 15 57 / 5%), 0 2px 8px rgb(14 15 57 / 5%);
    margin-bottom: 12px;

    .title {
      margin-bottom: 16px;
      color: var(--grey400);
      text-transform: uppercase;
      font-weight: 400;
      display: flex;
      align-items: center;

      span {
        margin-left: 4px;
        font-weight: 600;
        color: var(--grey600);
      }

      .manageButton {
        margin-left: auto;
        display: inline-block;
        padding: 8px;
        background-color: #FFF1ED;
        color: var(--brand);
        border-radius: 8px;
        cursor: pointer;

        &:hover {
          background-color: rgba(255, 241, 237, 0.75);
        }
      }
    }

    .item {
      padding: 16px 0;
      border-bottom: 1px solid var(--grey200);
      display: flex;
      align-items: center;
      justify-content: space-between;

      .flexItemTitle {
        color: var(--grey900);
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
      }

      .smallLink {
        margin-top: 2px;
        font-size: 13px;
        cursor: pointer;

        &:hover {
          color: var(--brand400);
        }
      }

      strong {
        margin-right: 4px;
        font-weight: 600;
        color: var(--grey600);
      }

      .paymentMethod {
        display: flex;
        flex-flow: column;
        align-items: flex-end;

        .paymentProvider {
          display: flex;
          align-items: center;

          &:first-of-type {
            margin-right: 0;
          }

          span {
            margin-right: 8px;
          }
        }

        .smallItem {
          margin-top: 2px;
          font-size: 13px;
          color: $dark-100;
          display: flex;

          span:first-of-type {
            margin-right: 4px;
          }
        }
      }

      &:last-of-type {
        border: none;
      }
    }

    .credits {
      padding-top: 44px;
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      border: none;

      .creditsTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: var(--grey400);
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        width: 100%;
        margin-bottom: 16px;

        .counter {
          color: var(--brand);
        }

        .remaining {
          color: var(--grey600);
        }
      }

      .creditsBar {
        width: 100%;
        height: 24px;
        background: var(--grey200);
        border-radius: 8px;
        position: relative;

        div {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          border-radius: 8px;
          background: var(--brand);
          max-width: 100%;
        }
      }
    }

    .actionButtons {
      display: flex;
      align-items: center;
      margin-left: auto;
    }

    .buttonContainer {
      display: flex;
      align-items: center;
      width: fit-content;
      margin-left: auto;
    }

    .tooltip {
      margin-right: 8px;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }

  .renewSubscriptionButton {
    padding: var(--button-padding);
    background-color: var(--button-background);
    border-radius: var(--button-radius);
    font-size: var(--fontsize-15);
    box-shadow: var(--button-boxShadow);
    color: var(--button-textColor);
    text-align: center;
    cursor: pointer;
    outline: none;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;

    &:hover {
      opacity: 0.9;
    }
  }

  .disabledButton {
    cursor: not-allowed;
    background-color: var(--grey300);
    box-shadow: 0 2px 8px var(--grey300);
  }

  .topUpButton {
    margin-left: 16px;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.planNameContainer {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.planNameContainerComment {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height, or 150% */
  
  
  color: #DE6139;

  cursor: pointer;
  
}