@import "src/styles/v2/variables";

.tutorial {
  color: #7F65FF;
  border-color: #7F65FF;
  // secondary: 131313
}
.sales {
  color: #32ACA0;
  border-color: #32ACA0;
  // secondary: #131313
}
.product_updates {
  color: #DE6139;
  border-color: #DE6139;
  // secondary: #131313
}
.roster {
  color: #E0B23B;
  border-color: #E0B23B;
  // secondary: #131313
}
.guest_musician {
  color: #E0B23B;
  border-color: #E0B23B;
  // secondary: #131313
}

.container {
  cursor: pointer;
  background: #111827;
  border-radius: 8px;
  
  &.mediumContainer {
    margin-top: 0;
  }
  @media only screen  and (min-width: 948px) and (max-width: 1216px)  {
    margin-top: 58px;
  }

  @media only screen and (min-width: $mobile) and (max-width: 948px)  {
    width: calc(948px / 3);
    display: flex;
    align-items: center;
  }

  .wrapper {
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    margin-left: 46px;

    @include mobile-breakpoint {
      flex-direction: column;
      margin-top: 58px;
      width: calc(100% - 32px);
      margin-bottom: -96px;
    }
  }
}

.contentSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  
  margin-top: 34px;
  margin-bottom: 40px;

  width: 320px;

  @media only screen and (min-width: $mobile) and (max-width: 1366px)  {
    width: 41%;
    margin-bottom: 24px;
  }

  @include mobile-breakpoint {
    width: 256px;
    margin-bottom: 204px;
  }

  &.bigContentSection {
    width: 274px;
    margin-left: 24px;
    margin-bottom: 14px;
  }

  &.mediumContentSection {
    width: 60%;
  }

  &.smallContentSection {
    margin-left: 24px;
    width: 285px;

    .button {
      margin-top: 83px;
      margin-bottom: -24px;
    }
    width: 85%;
  }

  @media only screen and (min-width: $mobile) and (max-width: 948px)  {
    width: 100%;
  }


  @include mobile-breakpoint {
    width: 100%;
  }


  @media only screen and (min-width: 1216px) and (max-width: 1366px)  {
    // when it changes to 2 cards
    margin-top: 32px;
  }

  @media only screen  and (min-width: $laptop) and (max-width: 1366px)  {
    margin-bottom: 24px;
  }

  .category {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
  }
  
  .header {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 125%;
    /* or 35px */
  
    display: flex;
    align-items: center;
  
    height: 80px;


    /* Grays/White */
  
    color: #FFFFFF;

    @include mobile-breakpoint {
      margin-right: 32px;
    }

    @media only screen and (max-width: 1366px)  {
      font-size: 28px;
      height: 70px;
    }
  }
  
  .subheader {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: var(--fontsize-14);
    line-height: 140%;
    height: 40px;
  
    /* Grays/White */
  
    color: #FFFFFF;
  
    opacity: 0.8;
  }

  .button {
    margin-top: 32px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: var(--fontsize-14);
    line-height: 150%;
    // width: calc(52%);

    @media only screen and (min-width: $mobile) and (max-width: 1366px)  {
      margin-top: 16px;
      margin-bottom: 16px;
    }

    // @media only screen and (min-width: $mobile) and (max-width: 948px)  {
    //   width: 100%;
    // }
    @include mobile-breakpoint {
      margin-top: 208px;
      position: absolute;
    }

    .buttonText {
      display: flex;
      justify-content: center;
      align-content: center;
      flex-wrap: nowrap;
      flex-direction: row;
      align-items: stretch;
      padding-left: 12px;
      padding-right: 12px;
      gap: 4px;
      span {
        padding-right: 3px;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        flex-wrap: nowrap;

      }
      &:has(> .container) {
        color: black;
        justify-content: center;
      }
    }

    .buttonTextWithIcon {
      display: flex;
      justify-content: center;
      align-content: center;
      flex-wrap: nowrap;
      flex-direction: row;
      align-items: stretch;
      padding-left: 12px;
      gap: 4px;
      span {
        padding-right: 3px;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        flex-wrap: nowrap;

      }
      @include mobile-breakpoint {
        margin-right: 32px;
      }
    }

    &.centerButton {
      display: flex !important;
      justify-content: center !important;
    }

    &.tutorial {
      color: #7F65FF;
      border: 1px solid #7F65FF;
      // secondary: 131313
      .icon {
        path {
          stroke: #7F65FF;
        }
      }
    }
    &.sales {
      color: #32ACA0;
      border: 1px solid #32ACA0;
      // secondary: #131313
      .icon {
        path {
          stroke: #32ACA0;
        }
      }
    }
    &.product_updates {
      color: #DE6139;
      border: 1px solid #DE6139;
      // secondary: #131313
      .icon {
        path {
          stroke: #DE6139;
        }
      }
    }

    &.roster {
      color: #E0B23B;
      border: 1px solid #E0B23B;
      // secondary: #131313
      .icon {
        path {
          stroke: #E0B23B;
        }
      }
    }

    &.guest_musician {
      color: #E0B23B;
      border: 1px solid #E0B23B;
      // secondary: #131313
      .icon {
        path {
          stroke: #E0B23B;
        }
      }
    }

    &.outline {
      box-shadow: none;
      border-radius: var(--button-radius);
      
      display: block;
      padding: 10px 0;
      background-color: transparent;
      text-align: center;
      cursor: pointer;
      outline: none;
      transition: border-color .15s, color .15s;
    }

    &.filled {
      display: flex;
      align-items: center;
      color: #111827;
      display: flex;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;

      @media only screen and (min-width: 1366px)  {
        margin-bottom: -3px;
      }

      @media only screen and (max-width: 1366px)  {
        // margin-bottom: -1px;
      }

      &.tutorial {
        background-color: #7F65FF;
        border: 1px solid #7F65FF;
        // secondary: 131313
      }
      &.sales {
        background-color: #32ACA0;
        border: 1px solid #32ACA0;
        // secondary: #131313
      }
      &.product_updates {
        background-color: #DE6139;
        border: 1px solid #DE6139;
        // secondary: #131313
      }
      &.roster {
        background-color: #E0B23B;
        border: 1px solid #E0B23B;
        // secondary: #131313
      }
      &.guest_musician {
        background-color: #E0B23B;
        border: 1px solid #E0B23B;
        // secondary: #131313
      }

      .icon {
        path {
          stroke: #111827;
        }
      }
    }
  }
}

.bigContainer {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 2;
  
  background: #111827;
  border-radius: 8px;

  .wrapper {
    display: flex;
    flex-direction: row;
    padding-left: 12px;

    @include mobile-breakpoint {
      flex-direction: column;
      margin-top: 58px;
      width: calc(100% - 32px);
      margin-bottom: -96px;
    }
  }

  .bigHeader {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 125%;
    /* or 35px */
  
    display: flex;
    align-items: center;
  
    height: 70px;


    /* Grays/White */
  
    color: #FFFFFF;

    @include mobile-breakpoint {
      margin-right: 32px;
    }

    @media only screen and (max-width: 1366px)  {
      font-size: 28px;
      height: 70px;
    }
  }
  .bigSubheader {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: var(--fontsize-14);
    line-height: 140%;
    height: 40px;
  
    /* Grays/White */
  
    color: #FFFFFF;
  
    opacity: 0.8;

  }
  .bigAnnouncementBtnWrapper {
    margin-top: -10px;
    margin-bottom: 22px;
  }
}

.smallContainer {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 0.95 1;
  // margin-left: 10px;

  @media only screen and (max-width: 1366px)  {
    display: none;
  }

  .wrapper {
    padding-left: 12px;
  }

  .smallHeader {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 125%;
    /* or 35px */
  
    display: flex;
    align-items: center;
  
    height: 70px;


    /* Grays/White */
  
    color: #FFFFFF;

    @include mobile-breakpoint {
      margin-right: 32px;
    }

    @media only screen and (max-width: 1366px)  {
      font-size: 28px;
      height: 70px;
    }
  }

  .smallAnnouncementBtnWrapper {
    margin-top: -15px;
    margin-bottom: -2px;

    .outline {
      margin-bottom: 0px;
    }
  }
}

.doubleAnnouncementContainer{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 32px;

  justify-content: space-between;
  align-content: center;
  align-items: center;
}
