@import 'src/styles/v2/variables.scss';
$content-z-index: 3000;

.header {
  @include headline-medium;
  color: $light-300;
  margin: 108px auto 40px auto;
  text-align: center;
  white-space: break-spaces;
  z-index: $content-z-index;
}

.subheader {
  @include text-medium;
  color: $light-700;
  max-width: 455px;
  position: relative;
  z-index: $content-z-index;
  text-align: left;

  @include mobile-breakpoint {
    max-width: 100%;
    padding: 0 24px;
  }

  p {
    margin-bottom: 24px;
  }
}

.button {
  margin-top: 24px;
  width: 500px;
  z-index: $content-z-index;

  @include mobile-breakpoint {
    width: calc(100% - 48px);
  }
}

.qaContainer {
  margin-top: 72px;
  max-width: 500px;
  padding-bottom: 48px;
  position: relative;
  z-index: $content-z-index;

  @include mobile-breakpoint {
    width: calc(100% - 48px);
  }

  .headerContainer {
    display: flex;
    flex-flow: column;
    align-items: center;

    h1 {
      margin-top: 16px;
      @include headline-medium;
      font-size: 30px;
      line-height: 36px;
      color: $light-300;
      white-space: break-spaces;
      text-align: center;
    }
  }
}
