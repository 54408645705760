@import "src/styles/v2/variables";

.modal.modal {
  width: 420px;
  max-width: 420px;
  height: fit-content;
  display: flex;
  flex-flow: column;
  padding: 24px;
  position: relative;


  @include mobile-breakpoint {
    width: 90vw;
  }

  h1 {
    color: var(--grey500);
    font-weight: 700;
    margin-bottom: 16px;
  }

  .description {
    color: var(--grey500);
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    margin-bottom: 32px;
  }

  .button {
    width: 100%;
  }
}